import { useCallback, useEffect, useMemo, useState } from "react";
import Chat from "../../../components/Chat/Chat";
import DefaultSelect from "../../../components/Select/Select";
import useIncidentListStore from "../../../stores/useIncidentListStore";
import useThemeStore from "../../../stores/useThemeStore";
import './IncidentDetail.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import bouncingloading from '../../../utils/images/bouncing-loading.svg';
import { motion } from 'framer-motion';
import useExtIncidentStore from "../../../stores/useExtIncidentStore";
import useOptionsStore from "../../../stores/useOptionsStore";


function IncidentDetail() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = useMemo(
        () => `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`,
        [colorScheme]
    );

    const {
        setWorkgroup,
        setResponsible,
        setService,
        setServiceComponent,
        setState
    } = useExtIncidentStore();
    const {
        fetchIncident,
        fetchIncidentSmooth,
        selectedIncident,
        selectedIncidentUuid,
        isIncLoading,
        serviceComponentList
    } = useIncidentListStore();

    const [selectedWorkgroup, setSelectedWorkgroup] = useState(null);
    const [selectedResponsible, setSelectedResponsible] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceComponent, setSelectedServiceComponent] = useState(null);
    const [selectedState, setSelectedState] = useState(null);

    // опции для выпадающих списков

    const workgroupOptions = useMemo(() => JSON.parse(localStorage.getItem('workgroupList')), []);
    const responsibleOptions = useMemo(() => JSON.parse(localStorage.getItem('responsibleList')), []);
    const servicesOptions = useMemo(() => JSON.parse(localStorage.getItem('servicesList')), []);
    const statesOptions = useMemo(() => JSON.parse(localStorage.getItem('statesList')), []);

    // функции изменения состояния селектов

    const handleWorkgroupChange = useCallback(async (selectedOption) => {
        setSelectedWorkgroup(selectedOption);
        await setWorkgroup(selectedOption.value, selectedIncidentUuid);
    }, [selectedIncidentUuid, setWorkgroup]);

    const handleResponsibleChange = useCallback(async (selectedOption) => {
        setSelectedResponsible(selectedOption);
        await setResponsible(selectedOption.value, selectedIncidentUuid);
    }, [selectedIncidentUuid, setResponsible]);

    const handleServiceChange = useCallback(async (selectedOption) => {
        setSelectedService(selectedOption);
        await setService(selectedOption.value, selectedIncidentUuid);
    }, [selectedIncidentUuid, setService]);

    const handleServiceComponentChange = useCallback(async (selectedOption) => {
        setSelectedServiceComponent(selectedOption);
        await setServiceComponent(selectedOption.value, selectedIncidentUuid);
    }, [selectedIncidentUuid, setServiceComponent]);

    const handleStateChange = useCallback(async (selectedOption) => {
        setSelectedState(selectedOption);
        await setState(selectedOption.value, selectedIncidentUuid);
    }, [selectedIncidentUuid]);

    // эффекты

    useEffect(() => {
        if (selectedIncident) {
            const initialOption = workgroupOptions?.find(option => option.value === selectedIncident.workgroupUuid);
            setSelectedWorkgroup(initialOption || null);
        }
    }, [selectedIncident, workgroupOptions]);

    useEffect(() => {
        if (selectedIncident) {
            const initialOption = responsibleOptions?.find(option => option.value === selectedIncident.responsibleUuid);
            setSelectedResponsible(initialOption || null);
        }
    }, [selectedIncident, responsibleOptions]);

    useEffect(() => {
        if (selectedIncident) {
            const initialOption = servicesOptions?.find(option => option.value === selectedIncident.serviceUuid);
            setSelectedService(initialOption || null);
        }
    }, [selectedIncident, servicesOptions]);

    useEffect(() => {
        if (selectedIncident) {
            const initialOption = serviceComponentList?.find(option => option.value === selectedIncident.serviceComponentUuid);
            setSelectedServiceComponent(initialOption || null);
        }
    }, [selectedIncident, serviceComponentList]);

    useEffect(() => {
        if (selectedIncident) {
            const initialOption = statesOptions.find(option => option.value === selectedIncident.stateUuid);
            setSelectedState(initialOption || null);
        }
    }, [selectedIncident, servicesOptions]);

    useEffect(() => {
        if (selectedIncidentUuid) {
            setSelectedWorkgroup(null);
            setSelectedResponsible(null);
            setSelectedService(null);
            setSelectedServiceComponent(null);
            fetchIncident(selectedIncidentUuid);
        }
    }, [selectedIncidentUuid, fetchIncident]);

    const IncidentDetailHeader = () => {
        return (
            <div className={`incident-detail__header incident-detail__header${colorSchemeClassName}`}>
                <p><span>Номер</span>{selectedIncident?.number}</p>
                <p><span>Дата</span>{selectedIncident?.date}</p>
                <p><span>Крайний срок</span>{selectedIncident?.deadline}</p>
            </div>
        )
    }

    const IncidentInitiatorInfo = () => {
        return (
            <div className={`incident-detail__initiator-block incident-detail__initiator-block${colorSchemeClassName}`}>
                <div className="incident-detail__initiator-row">
                    <p id="first-column">Инициатор</p>
                    <p id="second-column">{selectedIncident?.initiator}</p>
                </div>
                <div className="incident-detail__initiator-row">
                    <p id="first-column">Клиент</p>
                    <p id="second-column">{selectedIncident?.client}</p>
                </div>
                {/* <div className="incident-detail__initiator-row">
                    <p id="first-column">Телефон</p>
                    <p id="second-column">+79160085631</p>
                </div>
                <div className="incident-detail__initiator-row">
                    <p id="first-column">Mail</p>
                    <p id="second-column">Aleksey.Pozdeev@pridex.ru</p>
                </div>
                <div className="incident-detail__initiator-row">
                    <p id="first-column">Учётная запись</p>
                    <p id="second-column">PRIDEX\Aleksey.Pozdeev</p>
                </div> */}
            </div>
        )
    }

    const IncidentDescription = () => {
        return (
            <div className={`incident-description__block incident-description__block${colorSchemeClassName}`}>
                <OverlayScrollbarsComponent style={{ maxHeight: '200px', padding: '10px' }} options={{ scrollbars: { autoHide: "leave" } }}>
                    <p>---------------------------</p>
                    <p>Тема обращения: </p>
                    <p>{selectedIncident?.topic}</p>
                    <p>Описание обращения: </p>
                    <p>{selectedIncident?.description}</p>
                </OverlayScrollbarsComponent>
            </div>
        )
    }

    const IncidentDetailComponent = () => {
        let content;

        switch (true) {
            case isIncLoading:
                content = <img style={{
                    width: '50px',
                    position: 'absolute',
                    left: 'calc(50% - 20px)',
                }} src={bouncingloading} alt="Loading..." />;
                break;
            case selectedIncident && !isIncLoading:
                content = (
                    <motion.div
                        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, type: 'tween' }}
                    >
                        <IncidentDetailHeader />
                        <div className="incident-detail__first-row">
                            <IncidentInitiatorInfo />
                            <IncidentDescription />
                        </div>
                        <div className="incident-detail__second-row">
                            <div className="incident-detail__left-block">
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                    <div className="incident-detail__select-row">
                                        <p className={`incident-detail__select-row__p${colorSchemeClassName}`}>Ответственный</p>
                                        <DefaultSelect
                                            options={responsibleOptions}
                                            value={selectedResponsible}
                                            onChange={handleResponsibleChange}
                                            placeholder='Выберите...'
                                        />
                                    </div>
                                    <div className="incident-detail__select-row">
                                        <p className={`incident-detail__select-row__p${colorSchemeClassName}`}>Рабочая группа</p>
                                        <DefaultSelect
                                            options={workgroupOptions}
                                            value={selectedWorkgroup}
                                            onChange={handleWorkgroupChange}
                                            placeholder='Выберите...'
                                        />
                                    </div>
                                    <div className="incident-detail__select-row">
                                        <p className={`incident-detail__select-row__p${colorSchemeClassName}`}>Услуга</p>
                                        <DefaultSelect
                                            options={servicesOptions}
                                            value={selectedService}
                                            onChange={handleServiceChange}
                                            placeholder='Выберите...'
                                        />
                                    </div>
                                    <div className="incident-detail__select-row">
                                        <p className={`incident-detail__select-row__p${colorSchemeClassName}`}>Состав услуги</p>
                                        <DefaultSelect
                                            options={serviceComponentList}
                                            value={selectedServiceComponent}
                                            onChange={handleServiceComponentChange}
                                            placeholder='Выберите...'
                                        />
                                    </div>
                                    <div className="incident-detail__select-row">
                                        <p className={`incident-detail__select-row__p${colorSchemeClassName}`}>Состояние</p>
                                        <DefaultSelect
                                            options={statesOptions}
                                            value={selectedState}
                                            onChange={handleStateChange}
                                            placeholder='Выберите...'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="incident-detail__chat-block">
                                <div className={`incident-detail__switch incident-detail__switch${colorSchemeClassName}`}>
                                    <div className={`incident-detail__switch-button incident-detail__switch-button${colorSchemeClassName}`}>Чат</div>
                                    {/* <div className={`incident-detail__switch-button incident-detail__switch-button${colorSchemeClassName}`}>Комментарии</div> */}
                                </div>
                                <Chat />
                            </div>
                        </div>
                    </motion.div>
                );
                break;
            default:
                content = <p className={`p${colorSchemeClassName}`}>Выберите обращение из списка слева.</p>;
                break;
        }

        return content;
    };
    return (
        <>
            <motion.div
                className={`incident-detail__block incident-detail__block${colorSchemeClassName}`}>
                <IncidentDetailComponent />
            </motion.div>
        </>
    )
}

export default IncidentDetail;