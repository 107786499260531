import useThemeStore from "../../stores/useThemeStore";
import './Chat.css';
import file_icon from '../../utils/images/file.svg';
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/baseUrlApi";
import Cookies from 'js-cookie';

export default function Message({ message }) {
    const MAX_FILENAME_LENGTH = 25;
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const [fileLoadingMap, setFileLoadingMap] = useState({});

    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const getFile = async (file) => {
        try {
            if (!file.UUID) {
                return;
            }
            setFileLoadingMap(prevState => ({
                ...prevState,
                [file.UUID]: true
            }));
            const res = await axios.post(`${baseUrl}/externalapi/extGetFileData`,
                {
                    idFile: file.UUID
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );
            const blob = new Blob([base64ToArrayBuffer(res.data.Data)], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.Name;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        } finally {
            setFileLoadingMap(prevState => ({
                ...prevState,
                [file.UUID]: false
            }));
        }
    };


    const TruncatedFileName = ({ name }) => {
        const truncateFileName = (fileName, maxLength) => {
            if (fileName?.length <= maxLength) return fileName;
            const truncatedName = fileName.substring(0, maxLength / 2 - 3);
            const truncatedExtension = fileName.substring(fileName.lastIndexOf('.'));
            const remainingLength = maxLength - truncatedName.length;
            let remainingChars = fileName.substring(fileName.length - remainingLength);
            if (!remainingChars.endsWith(truncatedExtension)) {
                remainingChars += truncatedExtension;
            }
            return truncatedName + '...' + remainingChars;
        };

        return <span>{truncateFileName(name, MAX_FILENAME_LENGTH)}</span>;
    };

    return (
        <div className={`message__div message__div${colorSchemeClassName} ${message.IsMyMessage ? 'your-message' : ''}`}>
            <div className={`message__text-block`}>
                {message.Text}
            </div>
            {message.Files && message.Files.length > 0 && (
                <div className={`message__file-block`}>
                    {message.Files.map(file => (
                        (file.Name && file.UUID) ?
                            <div
                                onClick={() => getFile(file)}
                                key={file.UUID}
                                className={`message_file-div message_file-div${colorSchemeClassName}`}>
                                <img src={file_icon} alt='File'></img>
                                <p><TruncatedFileName name={file.Name} /></p>
                            </div>
                            : null
                    ))}
                </div>
            )}
            <div className="message__info-block">
                <p>{message.Autor}</p>
                <p>{message.Data}</p>
            </div>
        </div>
    );
}
