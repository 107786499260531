import { create } from 'zustand';

const useNotificationStore = create((set) => ({
    notifications: [],
    addNotification: (notification) => set((state) => ({
        notifications: [...state.notifications, { ...notification, id: Date.now(), visibleInToast: true }],
    })),

    hideNotificationInToast: (id) => set((state) => ({
        notifications: state.notifications.map((notification) =>
            notification.id === id ? { ...notification, visibleInToast: false } : notification
        ),
    })),
    clearAllNotifications: () =>
        set({ notifications: [] }), 
}));

export default useNotificationStore;
