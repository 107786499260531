import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseInput from '../../../components/Input/BaseInput/BaseInput';
import { ReactComponent as Search } from '../../../utils/images/search.svg';
import { ReactComponent as BackButton } from '../../../utils/images/arrow_left.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as All } from '../../../utils/images/knowledge.svg';
import { ReactComponent as VPN } from '../../../utils/images/remote.svg';
import { ReactComponent as MobileMail } from '../../../utils/images/mail.svg';
import { ReactComponent as Telegram } from '../../../utils/images/send.svg';
import { ReactComponent as Simcard } from '../../../utils/images/sim.svg';
import { ReactComponent as Lock } from '../../../utils/images/lock.svg';
function KnowledgeSearch({ setSearchQuery, setSelectedCategory, selectedCategory, guidesList }) {
    const [inputValue, setInputValue] = useState('');
    const [isCategoriesOpened, setCategoriesOpened] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);

    const categories = ['Все', ...new Set(guidesList.map(guide => guide.categoryName))];
    const categoryIcons = {
        'Все': All,
        'VPN': VPN,
        'Мобильная почта': MobileMail,
        'Корпоративная связь': Simcard,
        'Telegram': Telegram,
        'Безопасность': Lock
    };
    const location = useLocation();
    const navigate = useNavigate();

    const handleCategoryToggle = () => {
        if (isAnimating) return;
        setIsAnimating(true);
        setCategoriesOpened(prevState => !prevState);
    };

    const handleInputChange = (value) => {
        setInputValue(value);
        setSearchQuery(value);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        const params = new URLSearchParams(location.search);
        params.set('categorie', category);
        navigate({ search: params.toString() });
    };

    const getCategoryCount = (category) => {
        if (category === 'Все') {
            return guidesList?.length;
        }
        return guidesList?.filter(item => item.categoryName === category).length;
    };

    const containerVariants = {
        hidden: { opacity: 0, height: 0 },
        show: {
            opacity: 1,
            height: 'auto',
            transition: {
                staggerChildren: 0.1,
            }
        },
        exit: {
            opacity: 0,
            height: 0,
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        show: { opacity: 1, x: 0 },
        exit: { opacity: 0 }
    };

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    const animatedCategories = (
        <AnimatePresence>
            {isCategoriesOpened && (
                <motion.div
                    key="categories-list"
                    variants={containerVariants}
                    initial="hidden"
                    animate="show"
                    exit="exit"
                    onAnimationComplete={handleAnimationComplete}
                    className="knowledge-search__categories-list"
                >
                    {categories.map((category) => {
                        const Icon = categoryIcons[category] || null;
                        return (
                            <motion.div
                                key={category}
                                variants={itemVariants}
                                className={`knowledge-search__item ${selectedCategory === category ? 'knowledge-search__item_active' : ''}`}
                                onClick={() => handleCategoryClick(category)}
                            >
                                {Icon && <Icon />}
                                <span>{category}</span>
                                <span style={{ marginLeft: 'auto', width: '10px' }}>{getCategoryCount(category)}</span>
                            </motion.div>
                        );
                    })}
                </motion.div>
            )}
        </AnimatePresence>
    );

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const categorieFromUrl = params.get('categorie');

        if (categorieFromUrl && categories.includes(categorieFromUrl)) {
            setSelectedCategory(categorieFromUrl);
        }
    }, [location.search, categories, setSelectedCategory]);

    return (
        <div className="knowledge-search__div">
            <div className="knowledge-search__input-div">
                <Search />
                <input
                    className='knowledge-search__input'
                    placeholder="Поиск"
                    onChange={(e) => handleInputChange(e.target.value)}
                    value={inputValue}
                />
            </div>

            <motion.div
                className="knowledge-search__categories-div">
                <div
                    className='knowledge-search__categories-header'
                    onClick={handleCategoryToggle}
                >
                    <p style={{ fontSize: '16px' }}>Категории</p>
                    <BackButton
                        className={isCategoriesOpened ? 'rotate-icon-opened' : 'rotate-icon-closed'}
                    />
                </div>
                {animatedCategories}
            </motion.div>
        </div>
    );
}


export default KnowledgeSearch;
