import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import 'overlayscrollbars/overlayscrollbars.css';
import './UserChat.css';
import { ReactComponent as SendButton } from '../../../../utils/images/send.svg';
import { ReactComponent as ClipButton } from '../../../../utils/images/clip.svg';
import red_cross from '../../../../utils/images/red_cross.png';
import Message from "./Message";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../utils/baseUrlApi";
import Cookies from 'js-cookie';
import { ReactComponent as Loading } from '../../../../utils/images/spinner_loading.svg';
import TextareaAutosize from 'react-textarea-autosize';
import useUserIncidentStore from "../../../../stores/useUserIncidentStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { ReactComponent as PdfIcon } from '../../../../utils/images/pdf-icon.svg';
import { ReactComponent as ImageIcon } from '../../../../utils/images/image-icon.svg';
import { ReactComponent as ExcelIcon } from '../../../../utils/images/excel-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../../../utils/images/archive-icon.svg';
import { ReactComponent as TextIcon } from '../../../../utils/images/text-icon.svg';
import { ReactComponent as AudioIcon } from '../../../../utils/images/audio-icon.svg';
import { ReactComponent as DefaultFileIcon } from '../../../../utils/images/file.svg';
import { motion } from 'framer-motion';
function UserChat() {
    const MAX_FILE_SIZE_MB = 5;
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const currentColorScheme = colorScheme.getColorScheme();
    const { selectedIncident, selectedIncidentUuid, fetchIncidentSmooth } = useUserIncidentStore();
    const [inputValue, setInputValue] = useState("");
    const [fileBase64List, setFileBase64List] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const reversedMessagesHistory = selectedIncident?.TheHistoryOfCommunication.toReversed();
    const filteredMessagesHistory = reversedMessagesHistory?.slice(1);

    const getFileIcon = (extension) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return <PdfIcon />;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'svg':
            case 'webp':
            case 'gif':
                return <ImageIcon />;
            case 'xlsx':
            case 'xls':
            case 'csv':
                return <ExcelIcon />;
            case 'zip':
            case 'rar':
            case '7z':
                return <ArchiveIcon />;
            case 'txt':
            case 'json':
            case 'docx':
            case 'xml':
                return <TextIcon />;
            case 'mp3':
            case 'flac':
            case 'aac':
            case 'wma':
                return <AudioIcon />;
            default:
                return <DefaultFileIcon />;
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let filesData = fileBase64List.map(file => ({ NameFile: file.name, Data: file.base64Content }));
            const messageToSend = inputValue || (filesData.length > 0 ? 'Файл(ы):' : '');
            const res = await axios.post(`${baseUrl}/mobiledata/addCommentWithFiles/`,
                {
                    "UID": selectedIncidentUuid,
                    "Files": filesData.length > 0 ? filesData : [],
                    "Comment": messageToSend
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );
            await fetchIncidentSmooth(selectedIncidentUuid);
            setInputValue("");
            setFileBase64List([]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        const newFiles = Array.from(files).filter(file => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024);

        if (newFiles.length !== files.length) {
            alert(`Один или несколько файлов слишком большие. Максимальный размер файла: ${MAX_FILE_SIZE_MB} MB`);
        }

        newFiles.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const base64Content = base64String.split(',')[1];
                setFileBase64List(prevList => [...prevList, { name: file.name, base64Content }]);
            };
            reader.readAsDataURL(file);
        });
    };

    const ChatBlock = () => {
        return (
            <div className={`user-chat__main-block user-chat__main-block${colorSchemeClassName}`}>
                {filteredMessagesHistory.length > 0 ?
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: "leave" } }}
                        style={{ padding: '10px 10px' }}
                        events={{
                            initialized: (instance) => {
                                instance.elements().viewport.scrollTo(99999, 99999);
                            },
                        }}
                    >
                        {filteredMessagesHistory.map(message => {
                            return <Message message={message} key={message.Data} />
                        })}
                    </OverlayScrollbarsComponent>
                    :
                    <div className="user-chat__empty-chat">
                        <div className="user-chat__empty-chat__overlay">
                            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Сообщений пока нет</p>
                            <p style={{ fontSize: '14px' }}>Когда вы получите сообщение,<br></br> оно отобразится здесь</p>
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <div className={`user-chat__block user-chat__block${colorSchemeClassName}`}>
                <div className="user-chat__block__overlay">
                    <ChatBlock />
                    <div className={`user-chat__input-block user-chat__input-block${colorSchemeClassName}`}>
                        <form
                            className={`user-chat__input-row user-chat__input-row${colorSchemeClassName}`}
                            onSubmit={handleSendMessage}
                        >
                            <div className="user-chat__input-block__row">
                                <div className="user-chat__input-field">
                                    {fileBase64List.length > 0 && (
                                        <div className={`user-chat__file-area user-chat__file-area${colorSchemeClassName}`}>
                                            {fileBase64List.map((file, index) => (
                                                <div key={`${index}+${file.name}`} className={`user-chat__pinned-file user-chat__pinned-file${colorSchemeClassName}`}>
                                                    {getFileIcon(file?.name?.split('.').pop())}<span style={{ maxWidth: '50%', overflow: 'hidden' }}>{file.name}</span>
                                                    <img
                                                        onClick={() => setFileBase64List(prevList => prevList.filter((_, i) => i !== index))}
                                                        className='user-chat__file-area__cross'
                                                        alt=''
                                                        src={red_cross}
                                                    ></img>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className="user-chat__input__block">
                                        <motion.label whileTap={{ scale: 0.95 }} className={`user-chat__pin-button user-chat__pin-button${colorSchemeClassName}`}>
                                            <input type="file" onChange={handleFileChange} disabled={isLoading} />
                                            <ClipButton
                                                style={{
                                                    color: currentColorScheme === 'dark' ? 'rgba(255, 255, 255, .8)' : '#0082C8',
                                                }}
                                            />
                                        </motion.label>
                                        <TextareaAutosize
                                            placeholder="Напишите сообщение..."
                                            className={`user-chat__input user-chat__input${colorSchemeClassName}`}
                                            value={inputValue}
                                            minRows={1}
                                            maxRows={5}
                                            type="text"
                                            onChange={(e) => setInputValue(e.target.value)}
                                        ></TextareaAutosize>
                                    </div>
                                </div>
                                <motion.div className="user-chat__send-button-div" whileTap={{ scale: (isLoading || (fileBase64List.length == 0 && !inputValue)) ? 1 : 0.95 }}>
                                    {isLoading ?
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Loading style={{ width: '25px', height: '25px', color: 'var(--deny-button-text-color)' }} />
                                        </div>
                                        :
                                        <button

                                            className={`user-chat__send-button user-chat__send-button${colorSchemeClassName}`}
                                            type="submit"
                                            disabled={isLoading || (fileBase64List.length == 0 && !inputValue)}
                                        >
                                            <SendButton width={30} height={30} style={{ color: currentColorScheme === 'dark' ? '#fff' : '#0082C8' }} />
                                        </button>}
                                </motion.div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserChat;
