import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

const AnimateChangeInHeight = ({ children, className, childrenLayoutClassName }) => {
  const containerRef = useRef(null);
  const [height, setHeight] = useState('auto');
  const [prevHeight, setPrevHeight] = useState('auto');

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const observedHeight = entries[0].contentRect.height;

        if (observedHeight !== prevHeight) {
          setHeight(observedHeight);
          setPrevHeight(observedHeight);
        }
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [prevHeight]);

  return (
    <motion.div
      whileTap={{ scale: 0.99 }}
      className={`${className} overflow-hidden`}
      initial={{ height: 0 }}
      animate={{ height }}
      exit={{ height: 0 }}
      transition={{ duration: 0.2, type: 'tween', damping: 10, stiffness: 500 }}
    >
      <div className={childrenLayoutClassName} style={{ width: '100%' }} ref={containerRef}>{children}</div>
    </motion.div>
  );
};

export default AnimateChangeInHeight;
