import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ReactComponent as Close } from "../../../utils/images/close.svg";
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';


const PdfPopup = ({ isOpen, closePopup, selectedPdf }) => {
    const blockTransition = {
        initial: { opacity: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0 },
        transition: { duration: 0.3, type: 'tween' },
    };

    const popupRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        layoutId={`item-${selectedPdf}`}
                        initial={blockTransition.initial}
                        animate={blockTransition.animate}
                        exit={blockTransition.exit}
                        transition={blockTransition.transition}
                        className='popup__layout'
                        key='instuction-popup'
                    >
                        <div className='pdf-popup__body' ref={popupRef}>
                            <button className="popup__close-button" onClick={() => closePopup()}><Close color='var(--font-color)' /></button>

                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                <Viewer
                                    defaultScale="PageWidth"
                                    fileUrl={selectedPdf}
                                />
                            </Worker>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default PdfPopup;

