import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { motion } from "framer-motion";
import useThemeStore from "../../stores/useThemeStore";
import useAuthStore from "../../stores/useAuthStore";
import loading from '../../utils/images/bouncing-loading.svg';
import errorimg from '../../utils/images/error.svg';
import './AuthPage.css';

function AuthPage() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getUser, isLoading, isError, isSuccess, user, error } = useAuthStore();

    useEffect(() => {
        const authToken = searchParams.get('authToken');
        if (authToken) {
            Cookies.set('authToken', authToken, { expires: 7 });
            getUser(authToken);
        }
    }, [searchParams, getUser]);

    useEffect(() => {
        if (isSuccess) {
            const timeoutId = setTimeout(() => {
                navigate('/incidents');
            }, 2500);


            return () => clearTimeout(timeoutId);
        }

        if (isError) {
            const timeoutId = setTimeout(() => {
                navigate('/sign-in');
            }, 2500);


            return () => clearTimeout(timeoutId);
        }
    }, [isSuccess, isError, navigate]);

    const renderContent = () => {
        switch (true) {
            case isLoading:
                return (
                    <>
                        <img src={loading} alt="Loading..." />
                        <p>Выполняется вход...</p>
                    </>
                );
            case isError:
                return (
                    <>
                        <motion.img src={errorimg}></motion.img>
                        <div>
                            <p className="error-message">Произошла ошибка</p>
                            <p style={{ fontWeight: 'bold' }}>{error}</p>
                        </div>
                    </>
                );
            case isSuccess:
                return (
                    <>
                        <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                            <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22" />
                            <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17" />
                        </svg>
                        <div>
                            <p className="success-message">Добро пожаловать,</p>
                            <p className="success-message" style={{ fontWeight: 'bold' }}>{user.fullName}!</p>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50, transition: .3 }}
                transition={{ duration: 0.5 }}
                className={`auth-page__block auth-page__block${colorSchemeClassName}`}>
                {renderContent()}
            </motion.div>
        </div>
    );
}

export default AuthPage;
