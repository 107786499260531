import useThemeStore from '../../../stores/useThemeStore';
import './BaseInput.css';

function BaseInput({ placeholder, validity, disabled, type = 'text', value, onChange }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;

    return (
        <input
            className={`base-input base-input${colorSchemeClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            value={value || ''}
            type={type}
            onChange={(e) => onChange(e.target.value)}
        />
    );
}

export default BaseInput;
