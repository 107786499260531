import useThemeStore from "../../../../stores/useThemeStore";
import './UserChat.css';
import { ReactComponent as FileIcon } from '../../../../utils/images/file.svg';
import file_icon from '../../../../utils/images/file.svg';
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../utils/baseUrlApi";
import Cookies from 'js-cookie';
import { ReactComponent as PdfIcon } from '../../../../utils/images/pdf-icon.svg';
import { ReactComponent as ImageIcon } from '../../../../utils/images/image-icon.svg';
import { ReactComponent as ExcelIcon } from '../../../../utils/images/excel-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../../../utils/images/archive-icon.svg';
import { ReactComponent as TextIcon } from '../../../../utils/images/text-icon.svg';
import { ReactComponent as AudioIcon } from '../../../../utils/images/audio-icon.svg';
import { ReactComponent as DefaultFileIcon } from '../../../../utils/images/file.svg';

export default function Message({ message }) {
    const MAX_FILENAME_LENGTH = 25;
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const [fileLoadingMap, setFileLoadingMap] = useState({});

    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    function formatDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('.');
        const [hours, minutes] = timePart.split(':');

        return `${day}.${month}.${year.slice(-2)} ${hours}:${minutes}`;
    }

    const getFile = async (file) => {
        if (!file.UUID) return;

        setFileLoadingMap(prev => ({ ...prev, [file.UUID]: true }));
        try {
            const { data } = await axios.post(`${baseUrl}/externalapi/extGetFileData`,
                { idFile: file.UUID },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );

            const blob = new Blob([base64ToArrayBuffer(data.Data)], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.Name;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error fetching file:", error);
        } finally {
            setFileLoadingMap(prev => ({ ...prev, [file.UUID]: false }));
        }
    };

    const TruncatedFileName = ({ name }) => {
        const truncateFileName = (fileName, maxLength) => {
            if (fileName?.length <= maxLength) return fileName;

            const extension = fileName.slice(fileName.lastIndexOf('.'));
            const baseName = fileName.slice(0, maxLength / 2 - 3);
            const remainingLength = maxLength - baseName.length;
            let remainingChars = fileName.slice(-remainingLength);

            if (!remainingChars.endsWith(extension)) {
                remainingChars += extension;
            }

            return `${baseName}...${remainingChars}`;
        };

        return <span>{truncateFileName(name, MAX_FILENAME_LENGTH)}</span>;
    };

    const getFileIcon = (extension) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return <PdfIcon />;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'svg':
            case 'webp':
            case 'gif':
                return <ImageIcon />;
            case 'xlsx':
            case 'xls':
            case 'csv':
                return <ExcelIcon />;
            case 'zip':
            case 'rar':
            case '7z':
                return <ArchiveIcon />;
            case 'txt':
            case 'json':
            case 'docx':
            case 'xml':
                return <TextIcon />;
            case 'mp3':
            case 'flac':
            case 'aac':
            case 'wma':
                return <AudioIcon />;
            default:
                return <DefaultFileIcon />;
        }
    };

    const renderMessageText = (text) => {
        const lines = text.split('\n');
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        return lines.map((line, index) => {
            const parts = line.split(urlRegex);
            return (
                <span key={index}>
                    {parts.map((part, i) => {
                        if (part.match(urlRegex)) {
                            const truncatedText = part.length > 50 ? part.substring(0, 43) + '...' : part;
                            return (
                                <a
                                    key={`${i}-${part}`}
                                    href={part}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="message__link"
                                    title={part}
                                    style={{ color: colorScheme.getColorScheme() === 'dark' ? '#fff' : '#000' }}
                                >
                                    {truncatedText}
                                </a>
                            );
                        } else {
                            return <span key={`${i}-${part}`}>{part}</span>;
                        }
                    })}
                    <br />
                </span>
            );
        });
    };

    return (
        <div className={`user-message__div user-message__div${colorSchemeClassName} ${message.IsMyMessage ? 'your-message' : ''}`}>
            <div className="user-message__text-block">
                {renderMessageText(message.Text)}
            </div>
            {message.Files?.length > 0 && (
                <div className="user-message__file-block">
                    {message.Files.map(file => (
                        file.Name && file.UUID && (
                            <div
                                key={file.UUID}
                                onClick={() => getFile(file)}
                                className={`message_file-div message_file-div${colorSchemeClassName} ${fileLoadingMap[file.UUID] ? 'file-loading' : ''}`}
                                title={fileLoadingMap[file.UUID] ? 'Загрузка...' : 'Нажмите чтобы скачать'}
                            >
                                {getFileIcon(file?.Name?.split('.').pop())}
                                <p><TruncatedFileName name={file.Name} /></p>
                                {fileLoadingMap[file.UUID] && <span className="loading-indicator">⏳</span>}
                            </div>
                        )
                    ))}
                </div>
            )}
            <div className="user-message__info-block">
                <p id="message__autor">{message.Autor}</p>
                <p>{formatDate(message.Data)}</p>
            </div>
        </div>
    );
}
