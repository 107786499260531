import { create } from 'zustand';
import axios from 'axios';
import Cookies from 'js-cookie';
const usePopupStore = create((set) => ({
    serviceComponents: [],
    selectedService: null,
    selectedServiceComponent: null,
    isComponentsLoading: false,
    isSuccess: false,
    isError: false,

    setIsSuccess: (status) => set({ isSuccess: status }),
    setIsError: (status) => set({ isError: status }),
    setSelectedService: (service) => set({ selectedService: service }),
    setServiceComponents: (components) => set({ serviceComponents: components }),
    setIsComponentLoading: (status) => set({ isComponentsLoading: status }),

    fetchServiceComponent: async (serviceUuid) => {
        try {
            set({ isComponentsLoading: true });
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/externalapi/getServiceComponents`,
                {
                    servCompServiceUuid: serviceUuid
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${Cookies.get('authToken')}`,
                    }
                });
            set({ serviceComponents: response.data.ServiceComponents, isError: false });
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            set({ isError: true })
        } finally {
            set({ isComponentsLoading: false });
        }
    },
}));

export default usePopupStore;
