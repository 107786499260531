import AuthModule from '../../modules/AuthModule/AuthModule';
import './LoginPage.css';

function LoginPage() {
    return (
        <>
        <AuthModule />
        </>
    )
}

export default LoginPage;