import { useEffect } from 'react';
import useIncidentListStore from '../../../stores/useIncidentListStore';
import useThemeStore from '../../../stores/useThemeStore';
import IncidentListItem from './IncidentListItem';
import './IncidentListTable.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/styles/overlayscrollbars.css";
import useAuthStore from '../../../stores/useAuthStore';
function IncidentListTable() {

    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`
    const { incidentList, getIncidentList, isListLoading } = useIncidentListStore();
    const { isLoggedIn } = useAuthStore();

    useEffect(() => {
        if (isLoggedIn) {
            getIncidentList();
        }
    }, [])

    const IncidentListHeader = () => {
        return (
            <>
                <div className="incident-list__header">
                    <div className="incident-list__header__switch-block">
                        <div className={`incident-list__header__switch-item incident-list__header__switch-item${colorSchemeClassName}`}>Группы</div>
                        <div className={`incident-list__header__switch-item incident-list__header__switch-item${colorSchemeClassName}`}>Мои</div>
                        <div className={`incident-list__header__switch-item incident-list__header__switch-item${colorSchemeClassName}`}>Все</div>
                    </div>
                    <div className={`incident-list__header__toggle-block incident-list__header__toggle-block${colorSchemeClassName}`}>
                        <p>Показать только открытые</p>
                    </div>
                </div>

            </>
        )


    }

    return (
        <>
            <div className={`incident-list__block incident-list__block${colorSchemeClassName}`}>
                <IncidentListHeader />
                {isListLoading ?
                    <div style={{ padding: '0 10px', overflow: 'hidden' }}>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                        <div className={`loading-row loading-row${colorSchemeClassName}`}></div>
                    </div>

                    :
                    <OverlayScrollbarsComponent style={{ padding: '0 10px' }} options={{ scrollbars: { autoHide: "leave" } }}>
                        {incidentList?.map(item => {
                            return <IncidentListItem key={item.linkUuid} incident={item} />
                        })}
                    </OverlayScrollbarsComponent>
                }
            </div>
        </>
    )
}

export default IncidentListTable;