import { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import BaseInput from '../../components/Input/BaseInput/BaseInput';
import useThemeStore from '../../stores/useThemeStore';
import './AuthModule.css';
import useAuthStore from '../../stores/useAuthStore';
import qrcode from '../../utils/images/qr.png';
import qrcodewhite from '../../utils/images/qr-white.png';
import useOptionsStore from '../../stores/useOptionsStore';
import { ReactComponent as HidePassword } from '../../utils/images/hide-password.svg';
import { ReactComponent as HidePasswordActive } from '../../utils/images/hide-password_active.svg';
import { ReactComponent as PridexCover } from '../../utils/images/pridex-cover.svg';
import { validateUsername } from '../../utils/validation';
import CannotLoginPopup from './CannotLoginPopup';

function AuthModule() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const currentColorScheme = colorScheme.getColorScheme();
    const auth = useAuthStore();
    const { isMobile } = useOptionsStore();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [hidePassword, setHidePassword] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isPopupOpened, setPopupOpened] = useState(false);
    useEffect(() => {
        const { isValid, invalidChar } = validateUsername(username);
        if (!isValid) {
            setErrorMessage(`Введён некорректный символ: ${invalidChar}`);
            setIsButtonDisabled(true);
        } else {
            setErrorMessage('');
            setIsButtonDisabled(!username || !password);
        }
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        auth.getToken(username, password);
    };

    return (
        <>
        <form
            className={`auth-form auth-form${colorSchemeClassName}`}
            onSubmit={(e) => handleSubmit(e)}
        >
            <div className={`auth-form__form-div auth-form__form-div${colorSchemeClassName}`}>
                <div>
                    <p className='auth-form__title'>Pridex IT Support</p>
                    <p className='auth-form__subtitle'>
                        Создавайте обращения, контролируйте ход выполнения, общайтесь с исполнителями
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div className='input__div'>
                        <p style={{ opacity: 0.6, fontSize: '14px' }}>Логин</p>
                        <BaseInput
                            colorSchemeClassName={colorSchemeClassName}
                            placeholder='Dmitriy.Smirnov'
                            value={username}
                            onChange={setUsername}
                        />
                        {errorMessage && <p style={{ color: '#FF747ECC', fontSize: '14px' }}>{errorMessage}</p>}
                    </div>
                    <div className='input__div'>
                        <p style={{ opacity: 0.6, fontSize: '14px' }}>Пароль</p>
                        <BaseInput
                            colorSchemeClassName={colorSchemeClassName}
                            placeholder='• • • • • • • •'
                            value={password}
                            onChange={setPassword}
                            type={hidePassword ? 'password' : 'text'}
                        />
                        {hidePassword ? (
                            <HidePassword
                                fill={currentColorScheme === 'dark' ? '#fff9' : '#000'}
                                className='auth-form__hide-password'
                                onClick={() => setHidePassword(!hidePassword)}
                                title={'Показать пароль'}
                            />
                        ) : (
                            <HidePasswordActive
                                fill={currentColorScheme === 'dark' ? '#fff9' : '#000'}
                                className='auth-form__hide-password'
                                onClick={() => setHidePassword(!hidePassword)}
                                title={'Скрыть пароль'}
                            />
                        )}
                    </div>
                </div>
                <div className='auth-form__submit-block'>
                    <p
                        style={{
                            color: '#FF747ECC',
                            lineHeight: '1.5',
                            visibility: auth.isError && !auth.isLoading ? 'visible' : 'hidden',
                            position: 'absolute',
                            top: '-22px',
                            left: '0',
                            right: '0',
                            fontSize: '14px'
                        }}
                    >
                        Неверные учётные данные
                    </p>
                    <button className='auth-form__button' disabled={isButtonDisabled}>
                        {auth.isLoading ? 'Вход...' : 'Войти'}
                    </button>
                </div>
                <div className={`auth-form__separator auth-form__separator${colorSchemeClassName}`}></div>
                <button className='auth-form__secondary-button' type='button' onClick={() => setPopupOpened(true)}>
                    Не получается войти
                </button>
            </div>
            {!isMobile && (
                <div className={`auth-form__overlay auth-form__overlay${colorSchemeClassName}`}>
                    <PridexCover
                        className='auth-form__background-image'
                        style={{ color: currentColorScheme === 'dark' ? '#383838' : '#0082C8' }}
                    ></PridexCover>
                    <div className={`auth-form__qr-block auth-form__qr-block${colorSchemeClassName}`}>
                        <img
                            className='auth-form__qr-img'
                            src={currentColorScheme === 'dark' ? qrcodewhite : qrcode}
                        ></img>
                        <div style={{ maxWidth: '200px' }}>
                            <p style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '1' }}>
                                Всегда под рукой
                            </p>
                            <p
                                style={{
                                    fontSize: '14px',
                                    lineHeight: '1.2',
                                    marginTop: '5px',
                                    opacity: 0.6,
                                }}
                            >
                                Авторизуйтесь на смартфоне, чтобы иметь доступ ко всем возможностям
                                портала в любом месте, в любое время
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </form>
            <CannotLoginPopup isOpen={isPopupOpened} closePopup={setPopupOpened} />
        </>
    );
}

export default AuthModule;
