import { create } from 'zustand';

const useThemeStore = create((set, get) => ({
  colorScheme: {
    userChoice: localStorage.getItem('userColorScheme') || null,
    systemScheme: localStorage.getItem('systemColorScheme') || null,
  },

  changeColorScheme: (source, value) => {
    set((state) => ({
      colorScheme: {
        ...state.colorScheme,
        [source]: value,
      },
    }));

    localStorage.setItem(source === 'userChoice' ? 'userColorScheme' : 'systemColorScheme', value);
    const newScheme = get().getColorScheme();
    const newClassName = newScheme === 'dark' ? 'darkTheme' : 'lightTheme';
    get().updateBodyClass(newClassName);
  },

  getColorScheme: () => {
    const { userChoice, systemScheme } = get().colorScheme;
    return userChoice || systemScheme;
  },

  updateBodyClass: (className) => {
    document.body.className = `${className}`;
    set({ colorSchemeClassName: `${className}` });
  },

  colorSchemeClassName: `_${
    (localStorage.getItem('userColorScheme') || localStorage.getItem('systemColorScheme') || 'light') === 'dark'
      ? 'darkTheme'
      : 'lightTheme'
  }`,
}));

export default useThemeStore;
