import useThemeStore from "../../../../stores/useThemeStore";
import success from '../../../../utils/images/success.svg';
import { ReactComponent as SuccessImage } from '../../../../utils/images/success.svg';
function Success() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;

    return (
        <div className={`result__div result__div${colorSchemeClassName}`}>
            <SuccessImage style={{ padding: '20px' }} src={success} />
            <p style={{fontSize: '20px', fontWeight: 'bold'}}>Ваше обращение принято</p>
            <p style={{maxWidth: '250px', opacity: .6}}>Вы можете отслеживать его статус
                и общаться с исполнителем в чате </p>
        </div>
    );
}

export default Success;
