import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useNotificationStore from '../../stores/useNotificationStore';
import './NotificationModule.css';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackButton } from '../../utils/images/arrow_left.svg';
const NotificationToast = () => {
    const { notifications, hideNotificationInToast } = useNotificationStore();
    const navigate = useNavigate();

    const visibleNotifications = notifications.filter((n) => n.visibleInToast);


    useEffect(() => {
        const timers = visibleNotifications.map((notification) =>
            setTimeout(() => hideNotificationInToast(notification.id), 5000)
        );

        return () => timers.forEach(clearTimeout);
    }, [visibleNotifications, hideNotificationInToast]);

    const toastAnimation = {
        initial: { opacity: 0, x: 20 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 20 },
        transition: { duration: 0.3 },
    };

    return (
        <div className="notification-toast-container">
            <AnimatePresence>
                {visibleNotifications.map((notification) => (
                    <motion.div
                        key={notification.id}
                        className={`notification-toast ${notification.type}`}
                        initial={toastAnimation.initial}
                        animate={toastAnimation.animate}
                        exit={toastAnimation.exit}
                        transition={toastAnimation.transition}
                        onClick={() => hideNotificationInToast(notification.id)}
                    >
                        <div className="notification-content">
                            <strong>{notification.type.toUpperCase()}</strong>: {notification.message}
                            <br />

                        </div>
                        <div className='notification-toast__second-row'>
                            <span>{new Date(notification.date).toLocaleTimeString()}</span>
                            <div
                                className="view-center-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/notifications');
                                }}
                            >
                                <span>В центр уведомлений</span>
                                <BackButton />
                            </div>
                        </div>

                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export default NotificationToast;
