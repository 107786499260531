import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import './ReactCalendar.css';

function ReactCalendar({ value, onChange, isOpened, setCalendarOpened }) {
    const calendarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setCalendarOpened(false);
            }
        }
        
        if (isOpened) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened, setCalendarOpened]);

    const blockTransition = {
        initial: { opacity: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0 },
        transition: { duration: 0.3, type: 'tween' },
    };

    const handleDateChange = (newDate) => {
        onChange(newDate);
    }

    return (
        <>
            <AnimatePresence>
                {isOpened &&
                    <motion.div
                        ref={calendarRef}
                        initial={blockTransition.initial}
                        animate={blockTransition.animate}
                        exit={blockTransition.exit}
                        transition={blockTransition.transition}
                        className='calendar__layout'
                        key='calendar__layout'
                    >
                        <Calendar onChange={handleDateChange} value={value} locale='ru-RU' showNeighboringMonth={false} />
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
}

export default ReactCalendar;
