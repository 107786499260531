import { ReactComponent as Img1C } from '../../../utils/images/1c.svg';
import { ReactComponent as Bank } from '../../../utils/images/bank.svg';
import { ReactComponent as Network } from '../../../utils/images/network.svg';
import { ReactComponent as Sim } from '../../../utils/images/sim.svg';
import { ReactComponent as Laptop } from '../../../utils/images/laptop.svg';
import { ReactComponent as Software } from '../../../utils/images/software.svg';
import { ReactComponent as Autodesk } from '../../../utils/images/autodesk.svg';
import { ReactComponent as Print } from '../../../utils/images/print.svg';
import { ReactComponent as Remote } from '../../../utils/images/remote.svg';
import { ReactComponent as Files } from '../../../utils/images/files.svg';
import { ReactComponent as Email } from '../../../utils/images/email.svg';
import { ReactComponent as Multispace } from '../../../utils/images/multispace.svg';
import { ReactComponent as Pridex } from '../../../utils/images/pridex.svg'


export const Services = [
    {
        "Service": "1С",
        "ServiceUuid": "85e195dd-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000085",
        "image": Img1C,
    },
    {
        "Service": "Программное обеспечение",
        "ServiceUuid": "85e196c8-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000095",
        "image": Software
    },
    {
        "Service": "Ревит (Revit) | Автокад (Autocad)",
        "ServiceUuid": "5382048d-bc37-11ee-90a8-0050569f9f23",
        "ServiceCode": "0000000100",
        "image": Autodesk
    },
    {
        "Service": "Печать | МФУ и Плоттеры",
        "ServiceUuid": "85e195c2-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000082",
        "image": Print
    },
    {
        "Service": "Оборудование ",
        "ServiceUuid": "85e19692-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000089",
        "image": Laptop
    },
    {
        "Service": "Файловые ресурсы | Доступ к папкам",
        "ServiceUuid": "85e1966c-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000087",
        "image": Files
    },
    {
        "Service": "Удаленный доступ | VPN | VDI",
        "ServiceUuid": "85e1957d-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000076",
        "image": Remote
    },
    {
        "Service": "Мобильная связь",
        "ServiceUuid": "85e195b6-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000080",
        "image": Sim
    },
    {
        "Service": "Локальная сеть | Wi-Fi | Интернет",
        "ServiceUuid": "85e1955a-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000072",
        "image": Network
    },
    {
        "Service": "Электронная почта",
        "ServiceUuid": "85e1958e-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000078",
        "image": Email
    },
    {
        "Service": "ЭДО | Банк-клиенты",
        "ServiceUuid": "85e1969d-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000091",
        "image": Bank
    },
    {
        "Service": "Корпоративные порталы и web-сервисы",
        "ServiceUuid": "85e1956f-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000074",
        "image": Pridex
    },
    {
        "Service": "Multispace",
        "ServiceUuid": "85e196bb-7e13-11ee-90a2-0050569f9f23",
        "ServiceCode": "0000000093",
        "image": Multispace
    }
];
