import './FileInput.css';
import React, { useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import clip from '../../utils/images/clip.svg';
import red_cross from '../../utils/images/red_cross.png';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import useThemeStore from '../../stores/useThemeStore';
import useOptionsStore from '../../stores/useOptionsStore';
import { ReactComponent as Clip } from '../../utils/images/clip.svg';


function StyledDropzone({ setFilesArray, dropzoneDisabled }) {
    const colorScheme = useThemeStore();
    const currentColorScheme = colorScheme.getColorScheme();
    const { isMobile } = useOptionsStore();
    const [files, setFiles] = useState([]);
    const [acceptedFileCount, setAcceptedFileCount] = useState(0);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop: acceptedFiles => {
            if (acceptedFileCount + acceptedFiles.length <= 5) {
                acceptedFiles.forEach(file => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64String = reader.result;
                        const base64Content = base64String.split(',')[1];
                        const fileObject = {
                            Name: file.name,
                            Data: base64Content
                        };
                        setFiles(prevFiles => [...prevFiles, fileObject]);
                        setAcceptedFileCount(prevCount => prevCount + 1);
                    };
                    reader.readAsDataURL(file);
                });
            }
        },
        disabled: dropzoneDisabled ? dropzoneDisabled : false
    });

    const baseStyle = {
        flex: 1,
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        justifyContent: isMobile ? 'center' : 'left',
        alignItems: 'center',
        padding: isMobile ? '0px 5px' : '0px 15px',
        borderWidth: '1px',
        borderRadius: 10,
        borderColor: 'var(--border-color)',
        borderStyle: 'solid',
        height: isMobile ? '43px' : '43px',
        backgroundColor: currentColorScheme === 'dark' ? '#3d3d3d' : '#fff',
        color: 'var(--font-color)',
        outline: 'none',
        transition: 'border .2s ease-in-out',
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676',
        borderStyle: 'dashed'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = {
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        opacity: dropzoneDisabled ? 0.5 : 1
    }

    const removeFile = indexToRemove => {
        setFiles(files.filter((file, index) => index !== indexToRemove));
        setAcceptedFileCount(acceptedFileCount - 1);
    };

    const handleFileClick = (event) => {
        event.stopPropagation();
    }

    useEffect(() => {
        setFilesArray(files);
    }, [files, setFilesArray]);

    return (
        <div {...getRootProps({ style })}>
            {!isMobile && <Clip style={{ width: '25px', color: 'var(--font-color)' }} />}
            <input {...getInputProps()} />
            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 'calc(100% - 40px)' }}>
                <OverlayScrollbarsComponent
                    options={{ scrollbars: { autoHide: "leave" } }}
                    style={{ maxWidth: '100%', height: '100%', padding: '9px' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        {files.length > 0 ? (
                            files.map((file, index) => (
                                <div key={index} className="file-input__file" onClick={handleFileClick}>
                                    <p className='file-input__p' title={file.Name}>{file.Name}</p>
                                    <img className='file-input_cross' src={red_cross} onClick={() => removeFile(index)} alt="cross"></img>
                                </div>
                            ))
                        ) : (
                            <p style={{ margin: '0', userSelect: 'none', fontSize: '16px', fontWeight: 'normal', textWrap: 'nowrap' }}>{!isMobile ? 'Перетащите сюда Ваши файлы, или кликните по области.' : 'Прикрепить файл'}</p>
                        )}
                    </div>
                </OverlayScrollbarsComponent>
            </div>
        </div>
    );

}

export default StyledDropzone;
