import useThemeStore from "../../stores/useThemeStore";
import './KnowledgePage.css';
import KnowledgeSearch from "./KnowledgeSearch/KnowledgeSearchBar";
import KnowledgeTable from "./KnowledgeTable/KnowledgeTable";
function KnowledgePage() {

    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`
    const currentColorScheme = colorScheme.getColorScheme();

    return (
        <>
            <div className={`knowledge__main-block knowledge__main-block${colorSchemeClassName}`}>
                <KnowledgeTable />
            </div>
        </>
    )
}

export default KnowledgePage;