import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import 'overlayscrollbars/overlayscrollbars.css';
import useThemeStore from "../../stores/useThemeStore";
import './Chat.css';
import send from '../../utils/images/send.svg';
import red_cross from '../../utils/images/red_cross.png';
import useIncidentListStore from "../../stores/useIncidentListStore";
import Message from "./Message";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/baseUrlApi";
import Cookies from 'js-cookie';
import loading from '../../utils/images/spinner_loading.svg';
import TextareaAutosize from 'react-textarea-autosize';

function Chat() {
    const MAX_FILE_SIZE_MB = 5;
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const { selectedIncident, selectedIncidentUuid, fetchIncidentSmooth } = useIncidentListStore();
    const [inputValue, setInputValue] = useState("");
    const [fileBase64List, setFileBase64List] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const reversedMessagesHistory = selectedIncident?.TheHistoryOfCommunication.toReversed();

    const handleSendMessage = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            let filesData = fileBase64List.map(file => ({ NameFile: file.name, Data: file.base64Content }));
            const messageToSend = inputValue || (filesData.length > 0 ? 'Файл(ы):' : '');
            if (filesData.length === 0) {
                filesData = [{ Data: "", NameFile: "" }];
            }
            const res = await axios.post(`${baseUrl}/mobiledata/addCommentWithFiles/`,
                {
                    "UID": selectedIncidentUuid,
                    "Files": filesData,
                    "Comment": messageToSend
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );
            await fetchIncidentSmooth(selectedIncidentUuid);
            setInputValue("");
            setFileBase64List([]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        const newFiles = Array.from(files).filter(file => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024);

        if (newFiles.length !== files.length) {
            alert(`Один или несколько файлов слишком большие. Максимальный размер файла: ${MAX_FILE_SIZE_MB} MB`);
        }

        newFiles.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                const base64Content = base64String.split(',')[1];
                setFileBase64List(prevList => [...prevList, { name: file.name, base64Content }]);
            };
            reader.readAsDataURL(file);
        });
    };

    const ChatBlock = () => {
        return (
            <div className={`chat__main-block chat__main-block${colorSchemeClassName}`}>
                <OverlayScrollbarsComponent
                    options={{ scrollbars: { autoHide: "leave" } }}
                    style={{ padding: '10px 10px' }}
                    events={{
                        initialized: (instance) => {
                            instance.elements().viewport.scrollTo(9999, 9999);
                        },
                    }}
                >
                    {reversedMessagesHistory.map(message => {
                        return <Message message={message} key={message.Data} />
                    })}
                </OverlayScrollbarsComponent>
            </div>
        )
    }

    return (
        <>
            <div className={`chat__block chat__block${colorSchemeClassName}`}>
                <ChatBlock />
                <div className={`chat__input-block chat__input-block${colorSchemeClassName}`}>
                    {fileBase64List.length > 0 && (
                        <div className={`chat__file-area chat__file-area${colorSchemeClassName}`}>
                            {fileBase64List.map((file, index) => (
                                <div key={`${index}+${file.name}`} className={`chat__pinned-file chat__pinned-file${colorSchemeClassName}`}>
                                    Файл во вложении: <span style={{ maxWidth: '50%', overflow: 'hidden' }}>{file.name}</span>
                                    <img
                                        onClick={() => setFileBase64List(prevList => prevList.filter((_, i) => i !== index))}
                                        className='chat__file-area__cross'
                                        alt=''
                                        src={red_cross}
                                    ></img>
                                </div>
                            ))}
                        </div>
                    )}
                    <form
                        className={`chat__input-row chat__input-row${colorSchemeClassName}`}
                        onSubmit={handleSendMessage}
                    >
                        <label className={`chat__pin-button chat__pin-button${colorSchemeClassName}`}>
                            <input type="file" onChange={handleFileChange} disabled={isLoading}></input>
                        </label>
                        <TextareaAutosize
                            autoFocus
                            placeholder="Напишите сообщение..."
                            className={`chat__input chat__input${colorSchemeClassName}`}
                            value={inputValue}
                            maxRows={5}
                            type="text"
                            onChange={(e) => setInputValue(e.target.value)}
                        ></TextareaAutosize>
                        {isLoading ?
                            <img src={loading} alt="" style={{ width: '25px', height: '25px' }}></img>
                            :
                            <button
                                className={`chat__send-button chat__send-button${colorSchemeClassName}`}
                                type="submit"
                                disabled={isLoading}
                            >
                                <img src={send} alt="Send"></img>
                            </button>}
                    </form>
                </div>
            </div>
        </>
    )
}

export default Chat;
