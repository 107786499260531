import { useState } from 'react';
import useThemeStore from '../../stores/useThemeStore';
import IncidentList from './IncidentList/UserIncidentList';
import UserIncidentDetail from './IncidentDetail/UserIncidentDetail';
import './IncidentsPage.css';
import useUserIncidentStore from '../../stores/useUserIncidentStore';
import IncidentPopupDesktop from './IncidentPopup/IncidentPopupDesktop';

function IncidentsPageMobile() {
    const { selectedIncidentUuid, setSelectedIncidentUuid } = useUserIncidentStore();
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;

    const handleBackToList = () => {
        setSelectedIncidentUuid(null);
    };

    return (
        <div className={`incidents-page__block incidents-page__block${colorSchemeClassName}`}>
            {selectedIncidentUuid ? (
                <UserIncidentDetail onBack={handleBackToList} />
            ) : (
                <IncidentList />
            )}
            <IncidentPopupDesktop />
        </div>
    );
}

export default IncidentsPageMobile;
