import { useCallback, useEffect, useState } from "react";
import Chat from "./Chat/UserChat";
import useThemeStore from "../../../stores/useThemeStore";
import './UserIncidentDetail.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ReactComponent as BouncingLoading } from '../../../utils/images/bouncing-loading.svg';
import { motion } from 'framer-motion';
import useUserIncidentStore from "../../../stores/useUserIncidentStore";
import { ReactComponent as BackButton } from '../../../utils/images/arrow_left.svg';
import Rate from "../../../components/Rate/Rate";
import useOptionsStore from "../../../stores/useOptionsStore";
import FilesList from "./FilesList/FilesList";
import SolutionBlock from "./SolutionBlock/SolutionBlock";
import { ReactComponent as Arrow } from '../../../utils/images/arrow_right.svg';
import AnimateChangeInHeight from "../../../utils/AnimateHeightInChange";
function getAssignmentText(workgroup, state) {
    return `${state} на ${groupMapping[workgroup]}` || state;
}

const groupMapping = {
    'CS Online': 'группе Технической поддержки',
    'MSP Support': 'группе Технической поддержки',
    'Мероприятия Динамо': 'группе Технической поддержки',
    'Мероприятия Павелецкая': 'группе Технической поддержки',
    'ТП 1я линия': 'группе Технической поддержки',
    'ТП MR': 'группе Технической поддержки',
    'ТП Казань': 'группе Технической поддержки',
    'ТП Красноярск': 'группе Технической поддержки',
    'ТП Павелецкая': 'группе Технической поддержки',
    'ТП Питер': 'группе Технической поддержки',
    'Группа Locker Service': 'группе Поддержки Multispace',
    'Группа инженерных систем': 'группе Поддержки Multispace',
    'Группа мультимедиа систем': 'группе Поддержки Multispace',
    'Группа разработки Multispace': 'группе Поддержки Multispace',
    'Группа сервисов кафе': 'группе Поддержки Multispace',
    'Группа систем безопасности': 'группе Поддержки Multispace',
    'Группа аналитики 1С': 'группе Поддержки 1С',
    'Группа консультантов 1С': 'группе Поддержки 1С',
    'Группа разработки 1С': 'группе Поддержки 1С',
    'Группа стажеров 1С': 'группе Поддержки 1С',
    'Группа базовых ИТ-сервисов': 'группе Системного и сетевого администрирования',
    'Группа вычислительной инфраструктуры': 'группе Системного и сетевого администрирования',
    'Группа сетевого администрирования': 'группе Системного и сетевого администрирования',
    'Группа ИТ Менеджеров': 'группе ИТ Менеджеров',
    'Группа сопровождения web-сервисов': 'группе Сопровождения web-сервисов',
    'Тестовая группа': 'Тестовой группe'
};

function UserIncidentDetail({ onBack }) {
    const colorScheme = useThemeStore();
    const { isMobile } = useOptionsStore();
    const [descriptionOpened, setDescriptionOpened] = useState(true);
    const [isDetailsOpened, setDetailsOpened] = useState(true);
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const currentScheme = colorScheme.getColorScheme();
    const { fetchIncident, selectedIncident, selectedIncidentUuid, isIncLoading } = useUserIncidentStore();
    const isIncidentCompleted = selectedIncident?.state === 'Выполнено. Требует подтверждения';
    const directionForCompleted = isMobile ? 'column' : window.innerWidth < 1315 ? 'column' : 'row';
    const directionForNotCompleted = isMobile ? 'row' : 'row';
    useEffect(() => {
        if (selectedIncidentUuid) {
            fetchIncident(selectedIncidentUuid);
        }
        setDescriptionOpened(true);
    }, [selectedIncidentUuid, fetchIncident]);

    useEffect(() => {
        setDetailsOpened(true)
    }, [descriptionOpened])

    const setIncidentNull = () => {
        onBack(null);
    };

    const IncidentDetailHeader = () => (
        <div className={`user-incident-detail__header user-incident-detail__header${colorSchemeClassName}`}>
            {onBack &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }} onClick={setIncidentNull}>
                    <BackButton width={20} height={20} fill={currentScheme === 'dark' ? '#fff9' : '#fff'} />
                </div>
            }
            <p>
                <span>№ {selectedIncident?.number.replace(/^0+/, '')}</span>
            </p>
            <p>
                <span>от {selectedIncident?.date}</span>
            </p>
        </div>
    );

    const IncidentDescription = () => (
        <div className={`user-incident-description__block user-incident-description__block${colorSchemeClassName}`}>
            <div className="user-incident-detail__toggle-block" id="user-incident-detail__toggle-block">
                <div
                    onClick={() => setDescriptionOpened(true)}
                    className={`user-incident-detail__toggle-item ${descriptionOpened && 'user-incident-detail__toggle-item_active'}`}
                >
                    Описание
                </div>
                <div
                    onClick={() => setDescriptionOpened(false)}
                    className={`user-incident-detail__toggle-item ${!descriptionOpened && 'user-incident-detail__toggle-item_active'}`}
                >
                    Вложения ({selectedIncident?.FilesDefinitions.length})
                </div>
                <Arrow
                    width={15}
                    height={15}
                    style={{ transform: isDetailsOpened ? 'rotate(-90deg)' : 'rotate(90deg)', transition: '.2s ease-in-out', marginLeft: 'auto' }}
                    onClick={() => setDetailsOpened(!isDetailsOpened)}
                />

            </div>

            {isDetailsOpened && (
                descriptionOpened ? (
                    <OverlayScrollbarsComponent style={{ padding: '5px 0px' }} options={{ scrollbars: { autoHide: 'leave' } }}>
                        <motion.p
                            style={{ wordBreak: 'break-word' }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={blockTransition.transition}
                        >{selectedIncident?.description}</motion.p>
                    </OverlayScrollbarsComponent>
                ) : (
                    <FilesList isOpened={!descriptionOpened} filesArray={selectedIncident?.FilesDefinitions} />
                )
            )}
        </div>
    );

    const blockTransition = isMobile
        ? {
            initial: { opacity: 0, x: 100 },
            animate: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -100 },
            transition: { duration: 0.3, type: 'spring', stiffness: 300, damping: 40 },
        }
        : {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.3, type: 'Inertia', staggerChildren: 0.2 },
        };

    const IncidentTitle = () => (
        <div id="inc-title" className={`user-incident-description__block user-incident-description__block${colorSchemeClassName}`}>
            <p style={{ fontWeight: 'bold' }}>Тема</p>
            <p>{selectedIncident?.topic}</p>
        </div>
    );

    const IncidentDetailComponent = () => {
        if (isIncLoading) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <BouncingLoading
                        style={{ width: '50px', color: 'var(--deny-button-text-color)' }}
                        alt="Loading..."
                    />
                </div>
            );
        }

        if (selectedIncident && !isIncLoading) {
            return (
                <motion.div
                    style={{ padding: isMobile ? '10px 0px' : '10px 10px 10px 0px', width: isMobile ? '100%' : 'calc(100% - 10px)', height: 'calc(100% - 20px)', display: 'flex', flexDirection: 'column', gap: isMobile ? '10px' : '5px', alignItems: 'center' }}
                    initial={blockTransition.initial}
                    animate={blockTransition.animate}
                    exit={blockTransition.exit}
                    transition={blockTransition.transition}
                >
                    <IncidentDetailHeader />
                    <motion.div
                        transition={{ duration: 0.3, type: 'spring', stiffness: 150 }}
                        className={`user-incident-detail__first-row user-incident-detail__first-row${colorSchemeClassName}`}
                    >
                        <IncidentTitle />
                        <IncidentDescription />
                    </motion.div>

                    {(selectedIncident?.state === 'Закрыто' || selectedIncident?.state === 'Выполнено. Требует подтверждения') &&
                        <SolutionBlock incident={selectedIncident} />
                    }
                    <div
                        className={`user-incident-detail__switch user-incident-detail__switch${colorSchemeClassName}`}
                        style={{ borderRadius: '8px', flexDirection: isIncidentCompleted ? directionForCompleted : directionForNotCompleted }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '0px' : '5px', textAlign: 'left', width: '100%', alignItems: 'center', height: '35px' }}>
                            <span style={{ fontWeight: 'bold' }}>Статус:&nbsp;</span>
                            <p
                                title={(
                                    selectedIncident?.state === 'Назначено' ||
                                    selectedIncident?.state === 'Переназначено' ||
                                    selectedIncident?.state === 'В работе' ||
                                    selectedIncident?.state === 'Приостановлено' ||
                                    selectedIncident?.state === 'На уточнении' ||
                                    selectedIncident?.state === 'На тестировании' ||
                                    selectedIncident?.state === 'Передано разработчикам'

                                ) ? getAssignmentText(selectedIncident?.workgroup, selectedIncident?.state) : selectedIncident?.state}>
                                {(
                                    selectedIncident?.state === 'Назначено' ||
                                    selectedIncident?.state === 'Переназначено' ||
                                    selectedIncident?.state === 'В работе' ||
                                    selectedIncident?.state === 'Приостановлено' ||
                                    selectedIncident?.state === 'На уточнении' ||
                                    selectedIncident?.state === 'На тестировании' ||
                                    selectedIncident?.state === 'Передано разработчикам'

                                ) ? getAssignmentText(selectedIncident?.workgroup, selectedIncident?.state) : selectedIncident?.state}
                            </p>
                        </div>
                        <Rate />
                    </div>
                    <Chat />
                </motion.div>
            );
        }

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={`user-incident-detail__overlay user-incident-detail__overlay${colorSchemeClassName}`}
            >
                <div className="user-incident-detail__overlay-body">
                    <p>Обращение не выбрано</p>
                    <p>Выберите обращение из списка слева или создайте новое</p>
                </div>
            </motion.div>
        );
    };

    return (
        <div className={`user-incident-detail user-incident-detail${colorSchemeClassName}`}>
            {IncidentDetailComponent()}
        </div>
    );

}

export default UserIncidentDetail;
