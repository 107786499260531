import InputMask from "react-input-mask";
import Cookies from 'js-cookie';
import useThemeStore from "../../stores/useThemeStore";
import { useState, useRef } from "react";
import './PhoneInput.css';
import useOptionsStore from "../../stores/useOptionsStore";

const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
    }
    return phoneNumber;
};

function PhoneInput({ setPhoneNumber }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const { isMobile } = useOptionsStore();
    const userPhoneNumber = JSON.parse(Cookies.get('user')).phoneNumber;
    const [isPhoneNumberValid, setPhoneNumberValidity] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState(formatPhoneNumber(userPhoneNumber));

    const inputRef = useRef(null);

    const handlePhoneNumberCheckboxChange = (e) => {
        const isValid = e.target.checked;
        setPhoneNumberValidity(isValid);

        if (!isValid) {
            setPhoneNumberValue(formatPhoneNumber(userPhoneNumber));
            setPhoneNumber('');
        } else {
            setPhoneNumberValue('');
            setPhoneNumber('');
        }
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumberValue(e.target.value);
        setPhoneNumber(e.target.value);
    };

    const handlePhoneNumberChangeButtonClick = () => {
        setPhoneNumberValidity((prevState) => !prevState);

        if (isPhoneNumberValid) {
            setPhoneNumberValue(formatPhoneNumber(userPhoneNumber));
            setPhoneNumber('');
        } else {
            setPhoneNumberValue('');
            setPhoneNumber('');
            inputRef.current?.focus();
        }
    };

    return (
        <div className={`phone-input__div phone-input__div${colorSchemeClassName}`}>
            <p>Ваш контактный номер</p>

            <div className="phone-input__input-div">
                <InputMask
                    placeholder={isPhoneNumberValid ? '+7' : formatPhoneNumber(userPhoneNumber)}
                    className={`phone-input phone-input${colorSchemeClassName}`}
                    mask='+7 (999) 999-99-99'
                    disabled={!isPhoneNumberValid}
                    value={phoneNumberValue}
                    onChange={handlePhoneNumberChange}
                    pattern="\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}"
                    title="Номер телефона полностью"
                    required
                >
                    {inputProps => <input ref={inputRef} {...inputProps} />}
                </InputMask>
                <button
                    type="button"
                    className="phone-input__change-button"
                    onClick={handlePhoneNumberChangeButtonClick}
                >
                    {isPhoneNumberValid ? 'Отменить' : 'Изменить'}
                </button>
            </div>
        </div>
    );
}

export default PhoneInput;
