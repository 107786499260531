import axios from "axios";
import { create } from "zustand";
import { baseUrl, defaultHeaders } from '../utils/baseUrlApi';
import Cookies from 'js-cookie';
import { sortObjectByKey } from "../utils/SortFunctions";
import useIncidentListStore from "./useIncidentListStore";

const useExtIncidentStore = create((set) => ({

    setState: async (StateUuid , selectedIncidentUuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/performCustomActionWithIncident`,
                {
                    Action: 'ChangeState',
                    IncUuid: selectedIncidentUuid,
                    StateUuid : StateUuid 
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                },
            )
        } catch (error) {
            console.log(error);
        }
    },

    setServiceComponent: async (ServiceComponentUuid, selectedIncidentUuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/performCustomActionWithIncident`,
                {
                    Action: 'ChangeCompositionService',
                    IncUuid: selectedIncidentUuid,
                    CompositionServiceUuid: ServiceComponentUuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                },
            )
        } catch (error) {
            console.log(error);
        }
    },

    setService: async (ServiceUuid, selectedIncidentUuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/performCustomActionWithIncident`,
                {
                    Action: 'ChangeService',
                    IncUuid: selectedIncidentUuid,
                    ServiceUuid: ServiceUuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                },
            )
        } catch (error) {
            console.log(error);
        }
    },

    setResponsible: async (ResponsibleUuid, selectedIncidentUuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/performCustomActionWithIncident`,
                {
                    Action: 'ChangeResponsible',
                    IncUuid: selectedIncidentUuid,
                    ResponsibleUuid: ResponsibleUuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                },
            )
        } catch (error) {
            console.log(error);
        }
    },

    setWorkgroup: async (WorkgroupUuid, selectedIncidentUuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/performCustomActionWithIncident`,
                {
                    Action: 'ChangeWorkgroup',
                    IncUuid: selectedIncidentUuid,
                    WorkgroupUuid: WorkgroupUuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                },
            )
        } catch (error) {
            console.log(error);
        }
    }

}));

export default useExtIncidentStore;