import useThemeStore from "../../../stores/useThemeStore";
import { delay, motion } from "framer-motion";
import { ReactComponent as Arrow } from '../../../utils/images/arrow_right.svg'
import { useState } from "react";
const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: .5, type: 'tween' },
};

export function ServiceItem({ image: SvgComponent, title, onClick, uuid, disabled }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const iconColor = colorScheme.getColorScheme() === 'dark' ? '#fff' : '#000';
    const [isHovered, setIsHovered] = useState(false);
    const arrowColor = isHovered ? (colorScheme.getColorScheme() === 'dark' ? '#fff' : '#007bff') : (colorScheme.getColorScheme() === 'dark' ? '#ffffffcc' : '#000');

    return (
        <motion.button
            initial={blockTransition.initial}
            animate={blockTransition.animate}
            exit={blockTransition.exit}
            transition={blockTransition.transition}
            className={`service__item service__item${colorSchemeClassName}`}
            onClick={() => onClick(uuid)}
            disabled={disabled}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
        >
            {SvgComponent && renderSvg(SvgComponent, arrowColor)}
            <p style={{ textAlign: 'left' }}>{title}</p>
            <Arrow style={{ color: arrowColor, marginLeft: 'auto', height: '20px' }} />
        </motion.button>
    );
}

export function ServiceComponentItem({ image: SvgComponent, title, onClick, uuid, disabled, description }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const iconColor = colorScheme.getColorScheme() === 'dark' ? '#fff' : '#000';
    const [isHovered, setIsHovered] = useState(false);
    const arrowColor = isHovered ? (colorScheme.getColorScheme() === 'dark' ? '#fff' : '#007bff') : (colorScheme.getColorScheme() === 'dark' ? '#ffffffcc' : '#000');
    return (
        <motion.button
            initial={blockTransition.initial}
            animate={blockTransition.animate}
            exit={blockTransition.exit}
            transition={blockTransition.transition}
            className={`service-component__item service__item${colorSchemeClassName}`}
            onClick={() => onClick(uuid)}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            disabled={disabled}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {SvgComponent && renderSvg(SvgComponent, iconColor)}
                <p>{title}</p>
                <p style={{ fontSize: '14px', opacity: .6 }}>{description}</p>
            </div>
            <Arrow style={{ color: arrowColor, marginLeft: 'auto', alignSelf: 'center', minWidth: '10px', width: '10px' }} />
        </motion.button>
    );
}

export function renderSvg(SvgComponent, color) {
    return (
        <SvgComponent
            style={{ fill: color }}
            fill={color}
            aria-hidden="true"
        />
    );
}


