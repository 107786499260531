import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState, useMemo, useCallback } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import Cookies from 'js-cookie';
import axios from "axios";
import { defaultHeaders } from "../../../../utils/baseUrlApi";
import { ReactComponent as DeleteRow } from '../../../../utils/images/delete-row.svg';
import { ReactComponent as AddRow } from '../../../../utils/images/add-row.svg';
import DefaultSelect from "../../../../components/Select/Select";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};

const rowVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
};

function FolderAccessForm({ setCurrentStep }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = useMemo(
        () => `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`,
        [colorScheme]
    );

    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [notForMyself, setNotForMyself] = useState(false);
    const [folderRowsArray, setFolderRowsArray] = useState([{ value: '', permission: 'Чтение и запись', folderPath: '' }]);

    const {
        serviceComponents,
        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();

    const { iniciatorUuid } = JSON.parse(Cookies.get('user') || '{}');
    const selectedService = Services.find((item) => item.ServiceUuid === serviceUuid);
    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const formatFolderAccessMessage = () => {
        const greeting = "Здравствуйте, прошу предоставить доступ к следующим папкам:";
        const folderDetails = folderRowsArray.map(row => {
            return `Для кого: ${row.value || "Для себя"}\nПуть к папке: ${row.folderPath}\nТип доступа: ${typeof row.permission === 'string' ? row.permission : row.permission?.value}`;
        }).join("\n\n");
        return `${greeting}\n\n${folderDetails}`;
    };
    
    const description = useMemo(() => {
        const descriptionParts = [
            formatFolderAccessMessage(),
            phoneNumberValue.length > 1 ? `Контактный номер телефона: ${phoneNumberValue}` : '',
        ];
        return descriptionParts.filter(Boolean).join('\n');
    }, [folderRowsArray, phoneNumberValue]);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: selectedServiceComponent?.ServiceComponent,
                    Data: "12.12.2023 12:13:14",
                    Description: description,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    Files: filesArray
                },
                {
                    headers: defaultHeaders
                }
            );
            setIsSuccess(true);
        } catch (error) {
            console.error("Error submitting form:", error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    };

    const addRow = () => {
        setFolderRowsArray(prevRows => [...prevRows, { value: '', permission: 'Чтение и запись', folderPath: '' }]);
    };

    const removeRow = useCallback((index) => {
        setFolderRowsArray(prevRows => prevRows.filter((_, i) => i !== index));
    }, []);

    const handleRowChange = (index, key, value) => {
        setFolderRowsArray(prevRows => prevRows.map((row, i) =>
            i === index ? { ...row, [key]: value } : row
        ));
    };

    const handleSelectChange = (selectedOption, index) => {
        setFolderRowsArray(prevRows =>
            prevRows.map((row, i) =>
                i === index ? { ...row, permission: selectedOption } : row
            )
        );
    };

    const RowComponent = useCallback(({ item, index }) => (
        <motion.div
            className="popup__form__folder-row"
            key={index}
            variants={rowVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {index > 0 && <DeleteRow className="popup__form__folder-svg" onClick={() => removeRow(index)} />}
            <input
                style={{ width: notForMyself ? '50%' : '100%' }}
                className="popup__form__input"
                placeholder="\\pridex.local\dfs\DML\1C"
                value={item.folderPath}
                onChange={(e) => handleRowChange(index, 'folderPath', e.target.value)}
                required
            />
            <div style={{ width: '350px' }}>
                <DefaultSelect
                    placeholder={'Тип доступа'}
                    isSearchable={false}
                    options={[
                        { value: 'Чтение и запись', label: 'Чтение и запись' },
                        { value: 'Только чтение', label: 'Только чтение' }
                    ]}
                    value={item.permission}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                    required={true}
                />
            </div>
            {notForMyself && (
                <input
                    style={{ width: '50%' }}
                    className="popup__form__input"
                    placeholder="Мне, Илону Маску, Ивану Семёнову"
                    value={item.value}
                    onChange={(e) => handleRowChange(index, 'value', e.target.value)}
                    required
                />
            )}
        </motion.div>
    ), [removeRow, notForMyself]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                {...blockTransition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>

                <label className="checkbox-ios" htmlFor='toggle-folder-type'>Доступ для группы лиц
                    <input id='toggle-folder-type' type="checkbox" checked={notForMyself} onChange={(e) => setNotForMyself(e.target.checked)} />
                    <span className="checkbox-ios-switch"></span>
                </label>

                <motion.div
                    className="popup__form__rows-div"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className="popup__form__folder-row">
                        <p style={{ width: notForMyself ? '35%' : 'calc(100% - 255px)', textAlign: 'center' }}>Полный путь к папке</p>
                        <p style={{ width: '245px', textAlign: 'center' }}>Тип доступа</p>
                        {notForMyself && <p style={{ width: '33%', textAlign: 'center' }}>Кому</p>}
                    </div>
                    {folderRowsArray.map((item, index) => (
                        <RowComponent item={item} index={index} key={index} />
                    ))}
                    {folderRowsArray.length < 5 && <AddRow onClick={addRow} className="add-row-button" />}
                </motion.div>

                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />

                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading}>Отправить</button>
                </div>
            </motion.form>
        </div>
    );
}

export default FolderAccessForm;
