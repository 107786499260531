import { AnimatePresence, motion } from "framer-motion";
import useThemeStore from "../../stores/useThemeStore";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExitButton } from '../../utils/images/exit.svg';
import { ReactComponent as KnowledgeButton } from '../../utils/images/knowledge.svg';
import { ReactComponent as UserIcon } from '../../utils/images/user.svg';
import { ReactComponent as IncidentButton } from '../../utils/images/incident.svg';
import sun from '../../utils/images/sun.svg';
import moon from '../../utils/images/moon.svg';
import useAuthStore from "../../stores/useAuthStore";
import Cookies from 'js-cookie';
function HeaderMenu({ isMenuOpened, handleLayoutClick, setMenuOpened }) {
    const colorScheme = useThemeStore();
    const { logout, isLoggedIn, user } = useAuthStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const currentPath = window.location.pathname;
    const navigate = useNavigate();
    const container = {
        hidden: { opacity: 0, height: 0 },
        show: {
            opacity: 1,
            height: 'auto',
            transition: {
                staggerChildren: 0.1,
            },
        },
        exit: {
            opacity: 0,
            height: 0,
            transition: { duration: 0.2 },
        },
    };


    const item = {
        hidden: { opacity: 0, x: -20 },
        show: { opacity: 1, x: 0, transition: { duration: 0.2 } },
    };

    return (
        <>
            <AnimatePresence>
                {isMenuOpened && (
                    <motion.div
                        className='header__menu__layout'
                        key='header_menu_anim'
                        onClick={handleLayoutClick}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { duration: 0.2 } }}
                    >
                        <motion.div
                            className={`header__menu header__menu${colorSchemeClassName}`}
                            variants={container}
                            initial="hidden"
                            animate="show"
                        >
                            <motion.div variants={item}>
                                {(isLoggedIn && Cookies.get('user')) && (
                                    <div
                                        className={`header__menu-item header__menu-item${colorSchemeClassName}`}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <UserIcon
                                            style={{ color: 'var(--header-menu-icon-color)' }}
                                        />
                                        <span>{user.fullName}</span>
                                    </div>
                                )}
                            </motion.div>
                            <motion.div variants={item}>
                                {currentPath !== '/incidents' && (
                                    <div
                                        className={`header__menu-item header__menu-item${colorSchemeClassName}`}
                                        onClick={() => {
                                            setMenuOpened(false);
                                            navigate('/incidents');
                                        }}
                                    >
                                        <IncidentButton
                                            style={{ color: 'var(--header-menu-icon-color)' }}
                                        />
                                        <span>Обращения</span>
                                    </div>
                                )}
                            </motion.div>
                            <motion.div variants={item}>
                                {currentPath !== '/knowledge' && (
                                    <div
                                        className={`header__menu-item header__menu-item${colorSchemeClassName}`}
                                        onClick={() => {
                                            navigate('/knowledge');
                                            setMenuOpened(false);
                                        }}
                                    >
                                        <KnowledgeButton
                                            style={{ color: 'var(--header-menu-icon-color)' }}
                                        />
                                        <span>Инструкции</span>
                                    </div>
                                )}
                            </motion.div>
                            <motion.div variants={item}>
                                <div
                                    className={`header__menu-item header__menu-item${colorSchemeClassName}`}
                                    onClick={() => {
                                        colorScheme.changeColorScheme('userChoice', colorScheme.getColorScheme() === 'dark' ? 'light' : 'dark');
                                        setMenuOpened(false);
                                    }}
                                >
                                    <img
                                        src={colorScheme.getColorScheme() === 'dark' ? sun : moon}
                                        className={`header__theme-button`}
                                    />
                                    <span>Переключить тему</span>
                                </div>
                            </motion.div>
                            <motion.div variants={item}>
                                {isLoggedIn && (
                                    <div
                                        className={`header__menu-item header__menu-item${colorSchemeClassName}`}
                                        onClick={() => {
                                            setMenuOpened(false);
                                            logout();
                                        }}
                                    >
                                        <ExitButton
                                            className='header__exit-button'
                                            style={{ color: 'var(--header-menu-icon-color)' }}
                                        />
                                        <span>Выйти</span>
                                    </div>
                                )}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default HeaderMenu;