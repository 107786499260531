import { create } from 'zustand';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseUrl } from '../utils/baseUrlApi';

const useAuthStore = create((set) => ({
    user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null,
    token: Cookies.get('authToken') || null,
    isLoggedIn: !!Cookies.get('authToken'),
    isLoading: false,
    error: null,
    isError: false,
    isSuccess: false,

    getToken: async (username, password) => {
        set({ isLoading: true, error: null });
        try {
            const response = await axios.post(`https://support.pridex.ru/auth`, {
                "username": username,
                "password": password,
            }, {
                headers: { "Content-Type": "application/json" }
            });
            const token = response.data.Token;
            Cookies.set('authToken', token, { expires: 7 });
            set({ token, isLoggedIn: true, isLoading: false, isError: false });
            await useAuthStore.getState().getUser(token);
        } catch (error) {
            set({ error: error.message, isLoading: false, isError: true, isSuccess: false });
        }
    },

    getUser: async (token) => {
        set({ isLoading: true, error: null });
        try {
            const response = await axios.get(`${baseUrl}/mobiledatag/authenticateG`, {
                headers: { "Authorization": `Basic ${token}` }
            });
            const userData = {
                fullName: response.data.Full_name,
                phoneNumber: response.data.User_number,
                isKeyUser: response.data.Key_user,
                isModerator: response.data.User_consumer,
                iniciatorUuid: response.data.UIniciator,
            };
            set({ user: userData, isLoading: false, isSuccess: true, isLoggedIn: true });
            Cookies.set('user', JSON.stringify(userData), { expires: 7 });
        } catch (error) {
            set({ error: error.response?.data || error.message, isLoading: false, isError: true, isLoggedIn: false });
        }
    },
    logout: () => {
        Cookies.remove('authToken');
        Cookies.remove('user');
        set({ token: null, user: null, isLoggedIn: false, isError: false, isSuccess: false });
    }    
}));

export default useAuthStore;
