import React from "react";
import { motion } from "framer-motion";

function Switch({ isOn, handleToggleChange, className, label }) {
    return (
        <label
            className={`switch-container ${className || ""}`}
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                flexDirection: 'row-reverse',
                gap: '10px'
            }}
        >
            <input
                type="checkbox"
                checked={isOn}
                onChange={handleToggleChange}
                style={{ display: "none" }}
            />
            <motion.div
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "34px",
                    height: "14px",
                    backgroundColor: isOn
                        ? "var(--switch-color-checked)"
                        : "var(--switch-color-unchecked)",
                    borderRadius: "27px",
                    padding: "2px",
                    justifyContent: isOn ? "flex-end" : "flex-start"
                }}
                layout
                onClick={() => handleToggleChange({ target: { checked: !isOn } })}
            >
                <motion.div
                    style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                    }}
                />
            </motion.div>
            {label && (
                <span style={{ userSelect: "none" }}>{label}</span>
            )}
        </label>
    );
}

export default Switch;
