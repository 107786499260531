import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ReactComponent as Arrow } from '../../../../utils/images/arrow_right.svg';
import './SolutionBlock.css';
import { useState } from 'react';
import AnimateChangeInHeight from '../../../../utils/AnimateHeightInChange';

function SolutionBlock({ incident }) {
    const [isOpened, setIsOpened] = useState(true);



    return (
        <AnimateChangeInHeight className="solution__block">
            <div className='solution__overlay'>
                <div className='solution__header' onClick={() => setIsOpened(!isOpened)}>
                    <p>Решение</p>
                    <Arrow style={{ transform: isOpened ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
                </div>
                {isOpened &&
                    <div style={{marginTop: '10px', maxHeight: 'calc(100% - 35px)'}}>
                        <OverlayScrollbarsComponent>
                            {incident?.solution || 'Пустое решение'}
                        </OverlayScrollbarsComponent>
                    </div>
                }
            </div>
        </AnimateChangeInHeight>
    )
}

export default SolutionBlock;