import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as PdfIcon } from '../../../../utils/images/pdf-icon.svg';
import { ReactComponent as ImageIcon } from '../../../../utils/images/image-icon.svg';
import { ReactComponent as ExcelIcon } from '../../../../utils/images/excel-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../../../utils/images/archive-icon.svg';
import { ReactComponent as TextIcon } from '../../../../utils/images/text-icon.svg';
import { ReactComponent as AudioIcon } from '../../../../utils/images/audio-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../../utils/images/download.svg';
import { ReactComponent as DefaultFileIcon } from '../../../../utils/images/file.svg';
import Cookies from 'js-cookie';
import './FilesList.css';
import { useState } from "react";
import axios from "axios";
function FilesList({ filesArray, isOpened }) {
    const [fileLoadingMap, setFileLoadingMap] = useState({});
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.2 },
        },
    };

    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };


    const getFile = async (fileUUID) => {
        try {
            setFileLoadingMap(prev => ({ ...prev, [fileUUID]: true }));

            const res = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/externalapi/extGetFileData`,
                { idFile: fileUUID },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`,
                    },
                }
            );
            const blob = new Blob([base64ToArrayBuffer(res.data.Data)], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = res.data.Name;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setFileLoadingMap(prev => ({ ...prev, [fileUUID]: false }));
        }
    };


    const getFileIcon = (extension) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return <PdfIcon />;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'svg':
                return <ImageIcon />;
            case 'xlsx':
            case 'xls':
            case 'csv':
                return <ExcelIcon />;
            case 'zip':
            case 'rar':
            case '7z':
                return <ArchiveIcon />;
            case 'txt':
            case 'json':
            case 'docx':
            case 'xml':
                return <TextIcon />;
            case 'mp3':
            case 'flac':
            case 'aac':
            case 'wma':
                return <AudioIcon />;
            default:
                return <DefaultFileIcon />;
        }
    };

    const File = ({ file }) => {
        const fileExtension = file.Name.split('.').pop();
        return (
            <motion.div
                key={`file-list-item${file.UID}`}
                className="user-incident-detail__file"
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    {getFileIcon(fileExtension)}
                    <span>{file.Name || 'Безымянный файл'}</span>
                </div>
                <DownloadIcon className="file-list-item__download-button" style={{ width: '15px' }} onClick={() => getFile(file.UID)} />
            </motion.div>
        );
    };

    return (
        <>
            <AnimatePresence>
                {isOpened && (
                    <OverlayScrollbarsComponent style={{ padding: '0px 15px 0px 0px', maxHeight: '250px' }} options={{ scrollbars: { autoHide: 'leave' } }}>
                        {filesArray.length > 0 ? (
                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate="show"
                                className="user-incident-detail__file-list ">
                                {
                                    filesArray.map((file, index) => (
                                        <File key={index} file={file} />
                                    ))
                                }
                            </motion.div>
                        ) : (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ padding: '5px 0px' }}>
                                <p>Нет файлов для отображения</p>
                            </motion.div>
                        )}
                    </OverlayScrollbarsComponent>

                )}
            </AnimatePresence>
        </>
    );
}

export default FilesList;
