import axios from "axios";
import { create } from "zustand";
import { baseUrl } from '../utils/baseUrlApi';
import Cookies from 'js-cookie';
import { sortObjectByKey } from "../utils/SortFunctions";

const useIncidentListStore = create((set) => ({
    incidentList: null,
    isIncLoading: false,
    isListLoading: false,
    error: null,
    selectedIncidentUuid: null,
    selectedIncident: null,
    workgroupList: null,
    servicesList: null,
    serviceComponentList: null,
    responsibleList: null,

    setSelectedIncidentUuid: (selectedIncidentUuid) => set({ selectedIncidentUuid }),

    getIncidentList: async (count) => {
        try {
            set({ isListLoading: true });
            const response = await axios.post(`${baseUrl}/externalapi/getIncidentsList/`,
                {
                    count: count || "25",
                    initiatorUuid: "44d043c4-8ee6-11ee-90a7-0050569f9f23",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );
            set({
                isListLoading: false,
                incidentList: response.data.Incidents
            });
        } catch (error) {
            console.error('Error in Axios request', error);
            set({ error: error.message, isListLoading: false });
        }
    },

    fetchIncident: async (uuid) => {
        set({ isLoading: true });
        try {
            set({ isIncLoading: true });
            const response = await axios.post(`${baseUrl}/externalapi/extGetDetailIncidentInfo`,
                {
                    uuid: uuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                });
            if (response.data.serviceUuid) {
                const resComp = await axios.post(`${baseUrl}/externalapi/getServiceComponents`,
                    {
                        servCompServiceUuid: response.data.serviceUuid
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Basic ${Cookies.get('authToken')}`
                        }
                    }
                );

                const list = resComp.data.ServiceComponents.map(item => ({
                    value: item.ServiceComponentUuid,
                    label: item.ServiceComponent
                }));
                const sortedList = sortObjectByKey(list, "label");
                set({ serviceComponentList: sortedList })
            }
            set({
                selectedIncident: response.data,
                isIncLoading: false
            });
        } catch (error) {
            console.error('Error fetching incident', error);
            set({ error: error.message, isIncLoading: false });
        }
    },

    fetchIncidentSmooth: async (uuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/extGetDetailIncidentInfo`,
                {
                    uuid: uuid
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                });
            set({
                selectedIncident: response.data,
            });
        } catch (error) {
            console.error('Error fetching incident', error);
            set({ error: error.message });
        }
    }
}));

export default useIncidentListStore;