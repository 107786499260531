import axios from "axios";
import { create } from "zustand";
import { baseUrl } from '../utils/baseUrlApi';
import { sortObjectByKey } from "../utils/SortFunctions";
import Cookies from 'js-cookie';
const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

const useOptionsStore = create((set) => ({
    workgroupList: [],
    responsibleList: [],
    servicesList: [],
    statesList: [],
    servicesComponentsList: [],
    isLoading: true,
    error: null,
    isIncidentPopupOpened: false,

    setIncidentPopupOpened: (isOpened) => set({ isIncidentPopupOpened: isOpened }),

    checkAndSetOptions: async () => {
        await useOptionsStore.getState().getWorkgroups();
        await useOptionsStore.getState().getResponsibles();
        await useOptionsStore.getState().getServices();
        await useOptionsStore.getState().getStates();
    },

    isMobile: window.innerWidth < 768,
    setIsMobile: (value) => set({ isMobile: value }),

    getStates: async () => {
        try {
            const storedStates = getLocalStorageItem('statesList');
            let statesList = storedStates;

            if (storedStates) {
                set({ statesList: statesList, isLoading: false });
            }

            const response = await axios.post(`${baseUrl}/externalapi/getStates`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );

            const list = response.data.States.map(item => ({
                value: item.StateUuid,
                label: item.State
            }));
            const sortedList = sortObjectByKey(list, "label");

            if (!storedStates || JSON.stringify(sortedList) !== JSON.stringify(statesList)) {
                setLocalStorageItem('statesList', sortedList);
                set({ statesList: sortedList });
            }

        } catch (error) {
            console.log('Error in Axios request', error);
            set({ error: error.message, isLoading: false });
        } finally {
            set({ isLoading: false });
        }
    },

    getServices: async () => {
        try {
            const storedServices = getLocalStorageItem('servicesList');
            let servicesList = storedServices;

            if (storedServices) {
                set({ servicesList: storedServices, isLoading: false });
            }

            const response = await axios.post(`${baseUrl}/externalapi/getServices`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );

            const list = response.data.Services.map(item => ({
                value: item.ServiceUuid,
                label: item.Service
            }));
            const sortedList = sortObjectByKey(list, "label");

            if (!storedServices || JSON.stringify(sortedList) !== JSON.stringify(servicesList)) {
                setLocalStorageItem('servicesList', sortedList);
                set({ servicesList: sortedList });
            }

        } catch (error) {
            console.error('Error in Axios request', error);
            set({ error: error.message, isLoading: false });
        } finally {
            set({ isLoading: false });
        }
    },

    getWorkgroups: async () => {
        try {
            const storedWorkgroups = getLocalStorageItem('workgroupList');
            let workgroupList = storedWorkgroups;

            if (storedWorkgroups) {
                set({ workgroupList: storedWorkgroups, isLoading: false });
            }

            const response = await axios.post(`${baseUrl}/externalapi/getWorkgroups`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );

            const list = response.data.Workgroups.map(item => ({
                value: item.WorkgroupUuid,
                label: item.Workgroup
            }));
            const sortedList = sortObjectByKey(list, "label");

            if (!storedWorkgroups || JSON.stringify(sortedList) !== JSON.stringify(workgroupList)) {
                setLocalStorageItem('workgroupList', sortedList);
                set({ workgroupList: sortedList });
            }

        } catch (error) {
            console.error('Error in Axios request', error);
            set({ error: error.message, isLoading: false });
        } finally {
            set({ isLoading: false });
        }
    },

    getResponsibles: async () => {
        try {
            const storedResponsibles = getLocalStorageItem('responsibleList');
            let responsibleList = storedResponsibles;

            if (storedResponsibles) {
                set({ responsibleList: storedResponsibles, isLoading: false });
            }

            const response = await axios.post(`${baseUrl}/externalapi/getEmployers`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${Cookies.get('authToken')}`
                    }
                }
            );

            const list = response.data.Employers.map(item => ({
                value: item.EmployerUuid,
                label: item.Employer
            }));
            const sortedList = sortObjectByKey(list, "label");

            if (!storedResponsibles || JSON.stringify(sortedList) !== JSON.stringify(responsibleList)) {
                setLocalStorageItem('responsibleList', sortedList);
                set({ responsibleList: sortedList });
            }

        } catch (error) {
            console.error('Error in Axios request', error);
            set({ error: error.message, isLoading: false });
        } finally {
            set({ isLoading: false });
        }
    },
}));

export default useOptionsStore;

