import useThemeStore, { getClassNames } from '../../stores/useThemeStore';
import './Header.css';
import headerLogoColor from '../../utils/images/pridex_color.svg';
import headerLogoWhite from '../../utils/images/pridex_white.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import sun from '../../utils/images/sun.svg';
import moon from '../../utils/images/moon.svg';
import useAuthStore from '../../stores/useAuthStore';
import { ReactComponent as ExitButton } from '../../utils/images/exit.svg';
import { ReactComponent as HeaderLogo } from '../../utils/images/header__logo.svg'
import useOptionsStore from '../../stores/useOptionsStore';
import { AnimatePresence, motion } from 'framer-motion';
import useNotificationStore from '../../stores/useNotificationStore';
import HeaderMenu from './HeaderMenu';

function Header() {
    const navigate = useNavigate();
    const { logout, isLoggedIn, user } = useAuthStore();
    const { isMobile } = useOptionsStore();
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const currentPath = window.location.pathname;
    const [isMenuOpened, setMenuOpened] = useState(false);

    const { addNotification } = useNotificationStore();

    const isKeyUser = user?.isKeyUser;

    const handleLayoutClick = (event) => {
        if (event.target.classList.contains('header__menu__layout')) {
            setMenuOpened(false);
        }
    };


    return (
        <AnimatePresence>
            <motion.header
                className={`header header${colorSchemeClassName}`}>
                <div className='header__logo-block'>
                    <HeaderLogo style={{ height: '35px', color: colorScheme.getColorScheme() === 'dark' ? 'rgba(255,255,255, .8)' : '#000' }} />
                </div>
                {(isLoggedIn && !isMobile) && (
                    <div className='header__button-block'>
                        <button
                            className={`header__button header__button${colorSchemeClassName} ${currentPath === '/incidents' ? `header__button${colorSchemeClassName}_active` : ''}`}
                            onClick={() => navigate('/incidents')}
                        >
                            Обращения
                        </button>
                        {isKeyUser &&
                            <button
                                className={`header__button header__button${colorSchemeClassName} ${currentPath === '/knowledge' ? `header__button${colorSchemeClassName}_active` : ''}`}
                                onClick={() => { window.open('e1c://server/itilium-1c.inex-d.local/itilium#e1cib/app/Обработка.dev_РаботаКП:') }}
                            >
                                Личный кабинет
                            </button>
                        }
                        <button
                            className={`header__button header__button${colorSchemeClassName} ${currentPath === '/knowledge' ? `header__button${colorSchemeClassName}_active` : ''}`}
                            onClick={() => navigate('/knowledge')}
                        >
                            Инструкции
                        </button>
                        {/* <button
                        className={`header__button header__button${colorSchemeClassName} ${currentPath === '/knowledge' ? `header__button${colorSchemeClassName}_active` : ''}`}
                        onClick={() => navigate('/notifications')}
                    >
                        Уведомления
                    </button>
                    <button onClick={() => addNotification({
                        type: 'info',
                        message: 'Это уведомление, оно покажется в Toast на 5 секунд',
                        date: new Date(),
                    })}>Создать уведомление</button> */}
                    </div>
                )}
                {!isMobile ? (
                    <div className='header__exit-button-block'>
                        {isLoggedIn && (<span>{user?.fullName}</span>)}
                        <img
                            src={colorScheme.getColorScheme() === 'dark' ? sun : moon}
                            className={`header__theme-button`}
                            onClick={() => colorScheme.changeColorScheme('userChoice', colorScheme.getColorScheme() === 'dark' ? 'light' : 'dark')}
                        />
                        {isLoggedIn && (
                            <ExitButton
                                className='header__exit-button'
                                onClick={() => logout()}
                                style={{ color: colorScheme.getColorScheme() === 'dark' ? '#FFFFFFCC' : "#000" }}
                            />
                        )}
                    </div>
                ) : (
                    isLoggedIn && (
                        <>
                            <input type="checkbox" id="checkbox" checked={isMenuOpened} onChange={(e) => setMenuOpened(e.target.checked)} />
                            <motion.label whileTap={{ scale: 0.95 }} htmlFor="checkbox" className="toggle">
                                <div className="bar bar--top" />
                                <div className="bar bar--middle" />
                                <div className="bar bar--bottom" />
                            </motion.label>
                        </>
                    )
                )}
                <HeaderMenu isMenuOpened={isMenuOpened} handleLayoutClick={handleLayoutClick} setMenuOpened={setMenuOpened} />
            </motion.header>
        </AnimatePresence>
    );
}

export default Header;
