import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as DownloadIcon } from '../../../utils/images/download.svg';
function KnowledgeTableItem({ title, link, image, date, readTime, onClick, description }) {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => setIsImageLoaded(true);
    }, [image]);

    const blockTransition = {
        initial: { opacity: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0 },
        transition: { duration: 0.3, type: 'tween' },
    };

    const handleDownload = (event) => {
        event.stopPropagation();
        const linkElement = document.createElement('a');
        console.log(link)
        linkElement.href = link;
        linkElement.download = '';
        linkElement.click();
    };

    return (
        isImageLoaded && (
            <motion.div
                whileTap={{ scale: 0.99 }}
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className="knowledge-table__item"
                onClick={onClick}
            >
                <img alt="Guide" src={image} />
                <div className="knowledge-table__item__text-block">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <h2>{title}</h2>
                        <p style={{ color: 'var(--font-color-tr)', width: '100%' }}>{description}</p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            color: 'var(--font-color-tr)',
                        }}
                    >
                        <div>
                            <p>{date}</p>
                            {/* <p>~{readTime} мин</p> */}
                        </div>
                        <div className='knowledge-table__item__download-button' title='Скачать инструкцию'>
                            <DownloadIcon
                                onClick={handleDownload}
                                className="knowledge-table__item__download-button"
                            />
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    );
}

export default KnowledgeTableItem;
