import { useEffect, useState } from 'react';
import useThemeStore from '../../../stores/useThemeStore';
import UserIncidentListItem from './UserIncidentListItem';
import './UserIncidentListTable.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/styles/overlayscrollbars.css";
import useAuthStore from '../../../stores/useAuthStore';
import useOptionsStore from '../../../stores/useOptionsStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Plus } from '../../../utils/images/plus.svg';
import useUserIncidentStore from '../../../stores/useUserIncidentStore';
import { ReactComponent as BouncingLoading } from '../../../utils/images/bouncing-loading.svg';
import { ReactComponent as Folder } from '../../../utils/images/folder.svg';
import { ReactComponent as ErrorList } from '../../../utils/images/error__list.svg';
import { ReactComponent as Error } from '../../../utils/images/incident-error.svg';
import { ReactComponent as Cross } from '../../../utils/images/cross.svg';
import { motion } from 'framer-motion';
import Switch from '../../../components/ToggleSwitch/ToggleSwitch';

function IncidentList() {

    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const { incidentList, getIncidentList, isListLoading, setShowInWork, showInWork, error, warningError, setWarningError } = useUserIncidentStore();
    const { isLoggedIn, user } = useAuthStore();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { setIncidentPopupOpened, isIncidentPopupOpened } = useOptionsStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn && user) {
            getIncidentList(showInWork);
        }
    }, [showInWork, isLoggedIn, user]);

    const handleToggleChange = (event) => {
        const newShowInWork = event.target.checked;
        setShowInWork(newShowInWork);
    };

    const openPopup = () => {
        setSearchParams({ popup: 'true' });
    };
    const IncidentListHeader = () => {
        return (
            <div
                className={`user-incident-list__header user-incident-list__header${colorSchemeClassName}`}
            >
                <div
                    className={`user-incident-list__header__toggle-block user-incident-list__header__toggle-block${colorSchemeClassName}`}
                >
                    <Switch
                        isOn={showInWork}
                        handleToggleChange={handleToggleChange}
                        label="Показать открытые"
                    />
                </div>
                <button
                    className={`user-incident-list__header__button user-incident-list__header__button${colorSchemeClassName}`}
                    onClick={() => openPopup()}
                >
                    Создать <Plus fill="#fff" />
                </button>
            </div>
        );
    };

    const blockTransition = isMobile
        ? {
            initial: { opacity: 0, x: -100 },
            animate: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: 100 },
            transition: { duration: 0.3, type: 'spring', stiffness: 300, damping: 40 },
        }
        : {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.3, type: 'Inertia', staggerChildren: 0.2 },
        };

    return (
        <>
            <motion.div
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className={`user-incident-list__block user-incident-list__block${colorSchemeClassName}`}>
                <IncidentListHeader />
                {warningError &&
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '10px', position: 'relative' }}>
                        <div className='user-incident-list__error-block'>
                            <ErrorList />
                            <div className='user-incident-list__error-text-block'>
                                <h2>Технические неполадки</h2>
                                <p>Временно недоступен просмотр заявок </p>

                            </div>
                            <Cross onClick={() => setWarningError(null)} style={{ width: '25px', height: '25px', cursor: 'pointer' }} />
                        </div>
                    </div>
                }
                {error ? (
                    <div className='user-incident-list__empty-block' style={{ height: 'calc(100% - 115px)' }}>
                        <Error />
                        <p style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '5px' }}>Что-то пошло не так...</p>
                        <p style={{ maxWidth: '240px', fontSize: '14px' }}>Произошла ошибка при загрузке обращений, попробуйте позднее</p>
                    </div>
                ) : isListLoading ? (
                    isMobile ? (
                        <BouncingLoading
                            style={{ width: '50px', position: 'absolute', left: 'calc(50% - 20px)', top: 'calc(50% - 20px)', color: 'var(--deny-button-text-color)' }}
                            alt="Загрузка..."
                        />
                    ) : (
                        <div style={{ padding: '0 10px', overflow: 'hidden' }}>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                            <div className={`user-loading-row user-loading-row${colorSchemeClassName}`}></div>
                        </div>
                    )
                ) : (
                    <OverlayScrollbarsComponent style={{ padding: isMobile ? '0px 10px 5px 10px' : '0 10px', height: '100%', width: 'calc(100% - 20px)' }} options={{ scrollbars: { autoHide: "leave" } }}>
                        {incidentList && incidentList.filter(item => showInWork
                            ? !['Отклонено', 'Не согласовано', 'Закрыто'].includes(item.state)
                            : true).length === 0 ? (
                            <div className='user-incident-list__empty-block'>
                                <Folder />
                                <p style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '5px' }}>{showInWork ? 'Нет открытых обращений' : 'Нет обращений'}</p>
                                <p style={{ maxWidth: '240px', fontSize: '14px' }}>{showInWork ? 'В списке нет открытых обращений, можно посмотреть закрытые' : 'В списке нет обращений, можно создать новое'}</p>
                            </div>
                        ) : (
                            incidentList
                                ?.filter(item => showInWork
                                    ? !['Отклонено', 'Не согласовано', 'Закрыто'].includes(item.state)
                                    : true
                                )
                                .map(item => (
                                    <UserIncidentListItem key={item.linkUuid} incident={item} />
                                ))
                        )}
                    </OverlayScrollbarsComponent>

                )}
            </motion.div>
        </>
    )
}

export default IncidentList;
