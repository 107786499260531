import { useEffect } from 'react';
import useIncidentListStore from '../../stores/useIncidentListStore';
import useThemeStore from '../../stores/useThemeStore';
import IncidentDetail from './IncidentDetail/IncidentDetail';
import IncidentListTable from './IncidentListTable/IncidentListTable';
import './WorkSpacePage.css';


function WorkSpacePage() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`

    const {} = useIncidentListStore();

    return (
        <>
            <div className={`workspace__block workspace__block${colorSchemeClassName}`}>
                <IncidentListTable />
                <IncidentDetail />
            </div>
        </>
    )
}

export default WorkSpacePage;