import Select from 'react-select';
import useThemeStore from '../../stores/useThemeStore';
import { useEffect } from 'react';

const DefaultSelect = ({ options, placeholder, onChange, value, defaultValue, isSearchable, required, disabled, maxWidth }) => {
    const colorScheme = useThemeStore();
    const currentColorScheme = colorScheme.getColorScheme();

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%',
            fontFamily: 'Circe',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: state.isFocused ? 'var(--border-color-focus)' : 'var(--border-color)',
            textAlign: 'left',
            boxShadow: 0,
            '&:hover': {
                borderColor: 'var(--border-color-hover)'
            },
            opacity: state.isDisabled ? .4 : 1,
            backgroundColor: 'var(--input-background-color)',
            color: 'var(--font-color)',
            transition: '.3s ease-in-out',
            fontSize: '16px',
            height: '45px',
            maxWidth: maxWidth
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '6px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? currentColorScheme === 'dark' ? 'var(--border-color)' : '#0082C8' : currentColorScheme === 'dark' ? '#2F2F2' : '#fff',
            color: state.isSelected ? currentColorScheme === 'dark' ? '#fff' : '#fff' : 'var(--font-color)',
            '&:hover': {
                backgroundColor: state.isSelected ? currentColorScheme === 'dark' ? '#7D7D7D' : '#9AA6AD' : currentColorScheme === 'dark' ? '#000' : '#ebedef'
            },
            fontSize: '16px',
            transition: '.3s ease-in-out',
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            paddingLeft: '10px',
            fontSize: '16px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
            fontSize: '16px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '16px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            color: 'var(--border-color)'
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '16px',
            fontFamily: 'Circe',
            marginBottom: '0px',
            borderRadius: '10px',
            color: 'var(--font-color)'
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left',
            backgroundColor: currentColorScheme === 'dark' ? '#2F2F2F' : '#fff',
            borderColor: 'var(--border-color)',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
            maxWidth: maxWidth,
            padding: '0px',
            borderRadius: '10px',
            textAlign: 'left',
            zIndex: 9999,
            fontFamily: 'Circe',
            fontSize: '15px',
            border: '1px solid var(--border-color)',
            backgroundColor: 'var(--input-background-color)',
            overflow: 'hidden',
            boxShadow: 'none',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
            borderColor: 'var(--border-color)',
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
        }),
        indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#797D7F',
        }),
    };



    return (
        <Select
            isSearchable={isSearchable}
            options={options}
            placeholder={placeholder}
            styles={selectStyles}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            required={required}
            isDisabled={disabled}

        />
    )
}

export default DefaultSelect;
