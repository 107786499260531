import { useState, useEffect } from "react";

function AuthTgPage() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;
            tg.ready();
            const userInfo = tg.initDataUnsafe?.user;
            if (userInfo) {
                console.log('Данные пользователя:', userInfo);
                setUser(userInfo);
            } else {
                console.warn('Данные пользователя отсутствуют');
            }
        } else {
            console.warn('Telegram WebApp SDK недоступен');
        }
    }, []);

    return (
        <div style={{ color: 'var(--font-color)', padding: '10px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {user ? (
                <div>
                    <p>Привет, {user.first_name} {user.last_name || ''}!</p>
                    <p>Username: @{user.username}</p>
                    <p>Ваш Telegram ID: {user.id}</p>
                    {user.photo_url && <img src={user.photo_url} alt="Profile" />}
                    <p>Язык: {user.language_code}</p>
                    {user.is_premium && <p>Премиум пользователь</p>}
                </div>
            ) : (
                <p>Загрузка данных о пользователе...</p>
            )}
        </div>
    );
}

export default AuthTgPage;