import './IncidentListTable';
import useThemeStore from "../../../stores/useThemeStore";
import useIncidentListStore from '../../../stores/useIncidentListStore';
import { useEffect } from 'react';

function IncidentListItem({ incident }) {


    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`

    const { setSelectedIncidentUuid, selectedIncidentUuid } = useIncidentListStore();
    const isSelected = selectedIncidentUuid === incident.linkUuid;
    
    return (
        <>
            <div
                key={incident.linkUuid}
                className={`incident-list__item incident-list__item${colorSchemeClassName}${isSelected ? '_active' : ''}`}
                onClick={() => setSelectedIncidentUuid(incident.linkUuid)}>
                <div className={`incident-list__item__first-row incident-list__item__first-row${colorSchemeClassName}`}>
                    <p id="user__full-name">Нет данных</p>
                    <div className="incident-list__item__date-block">
                        <p id="incident__number">{parseInt(incident.number, 10)}</p>
                        <p id="incident__date">{incident.date}</p>
                    </div>
                </div>
                <div className={`incident-list__item__second-row incident-list__item__second-row${colorSchemeClassName}`}>
                    <div className="incident-list__item__detail-block">
                        <p id="incident__topic">{incident.topic}</p>
                        <p id="incident__description">{incident.description}</p>
                    </div>
                    {/* <div className='incident-list__item__ext-info'>
                        <p id="incident-status">{incident.state}</p>
                        <div className={`incident-list__item__tags-block incident-list__item__tags-block${colorSchemeClassName}`}>
                            <div className={`incident-list__item__tag incident-list__item__tag${colorSchemeClassName}${isSelected ? '_active' : ''}`}>
                                <p id="incident-group">ТП Павелецкая</p>
                            </div>
                            <div className={`incident-list__item__tag incident-list__item__tag${colorSchemeClassName}${isSelected ? '_active' : ''}`}>
                                <p id="responsible-user">Алексей Ленский</p>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        </>
    )
}

export default IncidentListItem;