import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useUserIncidentStore from '../../stores/useUserIncidentStore';
import useThemeStore from '../../stores/useThemeStore';
import './Rate.css';
import { ReactComponent as Star } from '../../utils/images/star.svg';
import RatePopup from './RatePopup';
import star from '../../utils/images/star.svg';
import star_filled from '../../utils/images/star_filled.svg';
import { ReactComponent as Dropdown } from '../../utils/images/dropDown.svg';

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: .3, type: 'Inertia' },
};


function Rate() {
    const { selectedIncident } = useUserIncidentStore();
    const isIncidentCompleted = selectedIncident?.state === 'Выполнено. Требует подтверждения';
    const isIncidentClosed = selectedIncident?.state === 'Закрыто';
    const incidentRate = selectedIncident?.grade;
    const [isPopupOpened, setPopupOpened] = useState(false);
    const [isCommentOpened, setCommentOpened] = useState(isIncidentClosed);
    const [isSuccess, setIsSuccess] = useState(false);
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;

    const ButtonsBlock = () => {
        return (
            <div className='rate__buttons-div'>
                <button
                    className='rate__apply-button'
                    onClick={() => {
                        setIsSuccess(true);
                        setPopupOpened(true);
                    }}>Подтвердить</button>
                <button
                    className='rate__deny-button'
                    onClick={() => {
                        setIsSuccess(false);
                        setPopupOpened(true);
                    }}>Отклонить</button>
            </div>
        );
    };

    return (
        <>
            <AnimatePresence>
                {(incidentRate > 0 && isIncidentClosed) &&
                    <div className='rate__block'>
                        <div className='rate__stars-div-det' title='Ваша оценка'>
                            {[...Array(5)].map((_, index) => (
                                <Star
                                    key={index}
                                    style={{width: '24px', height: '24px'}}
                                    className={`rate__star ${index <= (selectedIncident.grade - 1) ? 'rate__star--hover' : 'rate__star--default'}`}
                                />
                            ))}
                        </div>
                    </div>
                }
                {isIncidentCompleted && <ButtonsBlock />}
                <RatePopup isPopupOpened={isPopupOpened} setPopupOpened={setPopupOpened} isSuccess={isSuccess} />
            </AnimatePresence>
        </>
    );
}

export default Rate;
