import { Outlet } from 'react-router-dom';
import useThemeStore from '../../stores/useThemeStore';
import IncidentList from './IncidentList/UserIncidentList';
import './IncidentsPage.css';
import IncidentPopupDesktop from './IncidentPopup/IncidentPopupDesktop';
import UserIncidentDetail from './IncidentDetail/UserIncidentDetail';

function IncidentsPage() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;

    
    return (
        <div className={`incidents-page__block incidents-page__block${colorSchemeClassName}`}>
            <IncidentList />
            <UserIncidentDetail />
            <IncidentPopupDesktop />
        </div>
    );
}

export default IncidentsPage;
