import { ReactComponent as Close } from "../../utils/images/cross.svg";
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import './AuthModule.css';

const CannotLoginPopup = ({ isOpen, closePopup }) => {
    const blockTransition = {
        initial: { opacity: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0 },
        transition: { duration: 0.3, type: 'tween' },
    };

    const popupRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        initial={blockTransition.initial}
                        animate={blockTransition.animate}
                        exit={blockTransition.exit}
                        transition={blockTransition.transition}
                        className='popup__layout'
                        key='instuction-popup'
                    >
                        <div className='cannot-login__popup__body' ref={popupRef}>
                            <button className="cannot-login__close-button" onClick={() => closePopup()}><Close color='var(--font-color)' /></button>
                            <h2>Советы по&nbsp;устранению<br></br>трудностей при входе</h2>
                            <ul className="cannot-login__popup__ul">
                                <li>
                                    <span className="cannot-login__popup__number">1</span>
                                    Проверьте корректность ввода логина и&nbsp;пароля, а&nbsp;также раскладку&nbsp;клавиатуры и&nbsp;не&nbsp;включен&nbsp;ли CapsLock
                                </li>
                                <li>
                                    <span className="cannot-login__popup__number">2</span>
                                    Попробуйте войти на&nbsp;портал через 1С&nbsp;УПП: раздел &laquo;Технический отдел&raquo; &rarr; подраздел &laquo;Создать&raquo; &rarr; &laquo;Заявка&nbsp;ИТ&raquo;
                                </li>
                                <li>
                                    <span className="cannot-login__popup__number">3</span>
                                    Если ни один из вариантов не помог, пожалуйста, свяжитесь с технической поддержкой со своей рабочей почты
                                </li>
                            </ul>
                            <a href="mailto:support@pridex.ru?subject=Не%20удаётся%20войти%20на%20портал%20Pridex%20IT%20Support&body=Здравствуйте!%20Не%20удаётся%20войти%20на%20портал." className="cannot-login__popup__button">Написать письмо в поддержку</a>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default CannotLoginPopup;

