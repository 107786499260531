import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import useThemeStore from "../../stores/useThemeStore";
import { ReactComponent as Star } from '../../utils/images/star.svg';
import star_filled from '../../utils/images/star_filled.svg';
import Cookies from 'js-cookie';
import useUserIncidentStore from "../../stores/useUserIncidentStore";

function RatePopup({ isPopupOpened, setPopupOpened, isSuccess, token, incidentUuid }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const [hoveredStarIndex, setHoveredStarIndex] = useState(null);
    const [selectedRate, setRate] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isStepTwo, setStepTwo] = useState(false);
    const popupRef = useRef(null);
    const { selectedIncidentUuid, getIncidentList, fetchIncident } = useUserIncidentStore();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupOpened(false);
            }
        };

        if (isPopupOpened) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isPopupOpened]);

    const handleStarHover = (index) => {
        setHoveredStarIndex(index);
    };

    const handleStarLeave = () => {
        setHoveredStarIndex(null);
    };

    const confirmIncident = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/externalapi/performCustomActionWithIncident/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Basic ${Cookies.get('authToken')}`,
                },
                body: JSON.stringify({
                    "Action": "ConfirmIncident",
                    "IncUuid": `${selectedIncidentUuid}`,
                    "Rate": `${selectedRate}`,
                    "Commentary": `Комментарий к оценке:\n${inputValue}`,
                    "Confirm": `${isSuccess}`
                })
            });

            const result = await response.json();
            setRequestSuccess(true);
            if (!response.ok) {
                throw new Error(result.message || 'Error confirming incident');
            }
            setResponseMessage('Оценка успешно отправлена');
            setStepTwo(true);

            setTimeout(async () => {
                setPopupOpened(false);
                setStepTwo(false);
                await fetchIncident(selectedIncidentUuid);
                await getIncidentList();
            }, 3000);
        } catch (error) {
            setError(error.message);
            setRequestSuccess(false);
            setResponseMessage('Ошибка: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        confirmIncident(token, incidentUuid, selectedRate, inputValue, true);
    };

    return (
        <>
            <AnimatePresence>
                {isPopupOpened && (
                    <div className='rate-popup__layout' key={'rate-popup'}>
                        <motion.form
                            key="rate-popup"
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.3 }}
                            className={`rate-popup rate-popup${colorSchemeClassName}`}
                            ref={popupRef}
                            onSubmit={handleSubmit}
                        >
                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{isSuccess ? 'Подтвердите выполнение' : 'Обращение не выполнено'}</p>
                            <p className="rate-popup__subtitle">{isSuccess ? `Оцените качество выполненной работы и, по желанию, оставьте комментарий.` : 'Расскажите нам почему Вы считаете, что Ваше обращение не выполнено.'}</p>
                            {isSuccess && <div className='rate__stars-div'>
                                {[...Array(5)].map((_, index) => (
                                    <Star
                                        key={index}
                                        className={`rate__star ${index <= (hoveredStarIndex !== null ? hoveredStarIndex : selectedRate - 1) ? 'rate__star--hover' : 'rate__star--default'}`}
                                        onMouseEnter={() => handleStarHover(index)}
                                        onMouseLeave={handleStarLeave}
                                        onClick={() => {
                                            setRate(index + 1);
                                            setHoveredStarIndex(null);
                                        }}
                                    />
                                ))}
                            </div>}
                            <textarea
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                required={selectedRate <= 3}
                                placeholder='Оставьте комментарий'
                            />
                            <button className='rate-popup__submit-button' disabled={loading || isStepTwo}>
                                {loading ? 'Отправка...' : 'Отправить'}
                            </button>
                        </motion.form>
                    </div>
                )}
            </AnimatePresence>
        </>
    );
}

export default RatePopup;
